import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './Header.module.css';
/*import defaultAvatar from '../../img/ava-ufo.png'; // Import the default avatar*/
// Test New Ava
// import defaultAvatar from '../../img/avatars/hzkto.jpg'; // Import the default avatar
import defaultAvatar from '../../../img/avatars/nAva.png'; // Import the default avatar
import backArrow from '../../../img/avatars/backArrow.svg';
import profile from '../../../img/avatars/profile.svg';
import chat from '../../../img/avatars/chatMsg.svg';
import CustomModal from '../CustomModal';

const Header = ({ title }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const avatarUrl = localStorage.getItem('avatar') || defaultAvatar;

    console.log(window.history.state)
    console.log(window.history.state.usr?.pathname)

    const handleBack = () => {
        switch (location.pathname) {
            
            case '/postreg':
                navigate('/postreg');
                break;
            case '/postreg2':
                navigate('/postreg2');
                break;
            case '/dash':
                navigate('/dash');
                break;
            case '/step5':
                navigate('/dash');
                break;
            case '/step55':
                navigate('/step5');
                break;
            case '/profile':
                navigate('/myStations');
                break;
            case '/stationControl':
                navigate('/myStations');
                break;  
            default:
                navigate(-1);
        }
    };

    const handleGoUserProfile = () => {
        navigate("/profile");
    };

    const handleTgBot = () => {
        window.open("https://t.me/PSS_Support_ChargStations_bot", "_blank");
    };

    // const handleGoChat = () => {

    //     const isFeatureReady = false;
    //     if (!isFeatureReady) return; 
    //     navigate("/chat");
    // };

    const handleImageError = (event) => {
        event.target.src = defaultAvatar;
    };

    // Список страниц, на которых кнопки не должны отображаться
    const hideBackButtonPages = ['/step2', '/step3', '/pinoffer', '/pinoffer2', '/pin', '/postreg', '/dash', '/regform', '/connectComplete', '/myStations'];
    const hideAvatarPages = ['/step2', '/step3', '/pinoffer', '/pinoffer2', '/pin', '/regform', '/preprofile'];
    // const hideHeader = ['/step2', '/step3', '/pinoffer', '/pinoffer2', '/pin', '/start', '/regform'];
    const hideProfile = ['/profile', '/editProfile'];

    return (
        <div className={styles.header}>
            <div className={styles.container}>
                <div className={styles.backButton}>
                    {!hideBackButtonPages.includes(location.pathname) && (
                        <div className={styles.backArrow} onClick={handleBack}>
                            <img src={backArrow} alt="Назад" className={styles.backArrowImg} />
                        </div>
                    )}
                </div>

                <div className={styles.profileContainer}>
                    {!hideProfile.includes(location.pathname) && (
                        <div
                            className={styles.profile}
                            onClick={handleGoUserProfile}
                        >
                            <img
                                src={profile}
                                alt="Профиль пользователя"
                                className={styles.profileImg}
                                onError={handleImageError}
                            />
                        </div>
                    )}
                    <div
                        className={styles.chat}
                        onClick={handleTgBot}
                    >
                        <img
                            src={chat}
                            alt="Чат"
                            className={styles.chatImg}
                            onError={handleImageError}
                        />
                    </div>
                </div>

            </div>
        </div>
    );
};

export default Header;
