// fetchRegForm.js
import config from "../../../settings/config";
import { toast } from "react-toastify";

/**
 * Отправка данных профиля пользователя на сервер
 * @param {Object} data - Данные для отправки (name, email, birthday, carBrand, carModel)
 * @param {Function} navigate - Функция для навигации
 * @param {string} name - Имя пользователя (для передачи в состояние навигации)
 */
export async function requestSettings(data, navigate, name) {
    let lang = localStorage.getItem('language') === 'ru';
    try {
        const response = await fetch(`${config.app.host}/api/v1/user/profile`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('m_token')}`,
            },
            body: JSON.stringify(data),
        });

        const responseData = await response.json();

        if (!response.ok) {
            const errorMessage = responseData?.message || 'Неизвестная ошибка';
            console.log(errorMessage);
            toast.error('Ошибка: ' + errorMessage, {
                position: "top-center",
                autoClose: 3000,
            });
            return;
        }

        if (lang) {
            toast.success('Данные сохранены!', {
                position: "top-center",
                autoClose: 3000,
            });
        } else {
            toast.success("Data saved", {
                position: "top-center",
                autoClose: 3000,
            });
        }

        // Перенаправление на страницу /postreg с передачей имени
        navigate('/postreg', { state: { name } });
    } catch (error) {
        console.error('Ошибка при отправке запроса:', error);
    }
}