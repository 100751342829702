import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styles from './Profile.module.css';
import { checkToken, hasRole } from "../../Utils/TokenService";
import { useNavigate } from "react-router-dom";
import history from "../../../img/historyCharging.svg";
import language from "../../../img/language.svg";
import aboutApp from "../../../img/aboutApp.svg";
import help from "../../../img/helpIcon.svg";
import logout from "../../../img/logout.svg";
import avatar from "../../../img/avatars/nAva.jpg";
import config from '../../../settings/config';
import { toast } from "react-toastify";
import CustomModal from '../../Utils/CustomModal';
import Header from '../../Utils/Header/Header';
import localeRu from '../../../locales/ru/profile/profile.json';
import localeEn from '../../../locales/en/profile/profile.json';
import useAuthToken from "../../../hooks/useAuthToken";
import useStateBackend from "../../../hooks/useStateBackend";

const Profile = () => {
    const navigate = useNavigate();
    const avatarUrl = localStorage.getItem('avatar') || avatar;
    const [user, setUser] = useState();
    const [showModal, setShowModal] = useState(false);
    const [isDeveloper, setDeveloper] = useState();
    let lang = localStorage.getItem('language') === 'ru';

    const handleImageError = (event) => {
        event.target.src = avatar;
    };

    const token = useAuthToken();
    useStateBackend();

    useEffect(() => {
        requestUser();
        if (hasRole(token, "ROLE_DEV")) {
            setDeveloper(true);
        } else {
            setDeveloper(false);
        }
    }, []);

    async function requestUser() {
        try {
            const response = await fetch(`${config.app.host}/api/v1/user/getprofile`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('m_token')}`,
                },
            });

            const responseData = await response.json();
            setUser(responseData);

            if (!response.ok) {
                const errorMessage = responseData?.message || 'Неизвестная ошибка';
                // toast.error('Ошибка: ' + errorMessage, {
                //     position: "top-center",
                //     autoClose: 3000
                // }
                // );
            }
        } catch (error) {
        }
    }

    const confirmLogOut = async () => {
        // Выполняем логику выхода, например, очищаем токены и перенаправляем на страницу входа
        let mToken = localStorage.getItem('m_token');
        let resp = '';

        if (mToken) {
            try {
                const response = await fetch(`${config.app.host}/api/v1/vk/logout`, {
                    method: 'POST',
                    mode: 'cors',
                    headers: {
                        'Authorization': `Bearer ${mToken}`,
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                resp = await response.json();
                if (resp.error) {
                    clearLocalStorageExcept(['phone', 'pin', 'language']);
                }

            } catch (error) {
                clearLocalStorageExcept(['phone', 'pin', 'language']);
                window.location.href = '/';
            }
            // End request SMS code
            toast.success(lang ? "Вы вышли из аккаунта" : `Logout successfuly!`, {
                position: "top-center",
                autoClose: 3000
            });
            clearLocalStorageExcept(['phone', 'pin', 'language', 'backendState']);
            // localStorage.clear();
            window.location.href = '/'; // Перенаправляем на страницу входа
        }
    };

    function clearLocalStorageExcept(excludedKeys) {
        for (let i = localStorage.length - 1; i >= 0; --i) {
            let key = localStorage.key(i);
            if (!excludedKeys.includes(key)) {
                localStorage.removeItem(key);
            }
        }
    }

    const handleButton = () => {
    }

    const handleEditUser = (user) => {
        navigate('/editProfile', { state: { user } });
    };

    const handleHistory = () => {
        navigate('/history');
    };

    const handleLanguage = () => {
        navigate('/language');
    };

    const handleAboutApp = () => {
        navigate('/about');
    };

    const handleHelp = () => {
        navigate('/help');
    };

    const handleLogOut = () => {
        setShowModal(true);
    };

    const handleSecret = () => {
        navigate('/secret');
    }

    return (
        <div className={styles.container}>
            <Header />
            <div className={styles.sectionTitle}>
                <img
                    src={avatarUrl}
                    className={styles.userImage}
                    alt='avatar'
                    onError={handleImageError} />
                <div
                    className={styles.titleContent}
                    onClick={() => user && handleEditUser(user)}
                >
                    <p className={styles.titleText}>{user?.name}</p>
                    <p className={styles.titleText}>{user?.email}</p>
                </div>
            </div>

            <div className={styles.mainSection}>
                <button
                    type="button"
                    onClick={handleHistory}
                    className={styles.menuButton}
                >
                    <div className={styles.buttonText}>
                        <img src={history} className={styles.lightning} alt='lightning' /> <p className={styles.textButtons}>{lang ? localeRu.chargingHistory : localeEn.chargingHistory}</p>
                    </div>
                    <span className={styles.arrow}>➔</span>
                </button>
                <button
                    type="button"
                    onClick={handleLanguage}
                    className={styles.menuButton}
                >
                    <div className={styles.buttonText}>
                        <img src={language} className={styles.lightning} alt='lightning' /> <p className={styles.textButtons}>{lang ? localeRu.language : localeEn.language}</p>
                    </div>
                    <span className={styles.arrow}>➔</span>
                </button>
                <button
                    type="button"
                    onClick={handleAboutApp}
                    className={styles.menuButton}
                >
                    <div className={styles.buttonText}>
                        <img src={aboutApp} className={styles.lightning} alt='lightning' /> <p className={styles.textButtons}>{lang ? localeRu.aboutApp : localeEn.aboutApp}</p>
                    </div>
                    <span className={styles.arrow}>➔</span>
                </button>
                <button
                    type="button"
                    onClick={handleHelp}
                    className={styles.menuButton}
                >
                    <div className={styles.buttonText}>
                        <img src={help} className={styles.lightning} alt='lightning' /> <p className={styles.textButtons}>{lang ? localeRu.help : localeEn.help}</p>
                    </div>
                    <span className={styles.arrow}>➔</span>
                </button>
                <button
                    type="button"
                    onClick={handleLogOut}
                    className={styles.menuButton}
                >
                    <div className={styles.buttonText}>
                        <img src={logout} className={styles.lightning} alt='lightning' />
                        <p className={styles.textButtons}>{lang ? localeRu.logout : localeEn.logout}</p>
                    </div>
                </button>

                {/* Кастомное модальное окно */}
                <CustomModal
                    show={showModal}
                    onClose={() => setShowModal(false)}
                    onConfirm={confirmLogOut}
                    textMsg={lang ? localeRu.logoutConfirmTitle : localeEn.logoutConfirmTitle}
                    textQuestions={lang ? localeRu.logoutConfirmText : localeEn.logoutConfirmText}
                    yes={lang ? "Да" : "Yes"}
                    no={lang ? "Нет" : "No"}
                />
            </div>
            {isDeveloper && (
                <div
                    className={styles.secret}
                    onClick={handleSecret}
                > <p className={styles.secretText}>{lang ? localeRu.developerSection : localeEn.developerSection}</p> </div>
            )}
        </div>
    );
}

export default Profile;