import React, { useEffect, useState } from 'react';
import styles from './PostRegScreen2.module.css';
import { checkToken } from "../../Utils/TokenService";
import { useNavigate } from "react-router-dom";
import mobileSignal from '../../../img/signal.svg';
import { Button, Modal } from "react-bootstrap";
import localeRu from '../../../locales/ru/auth/postReg2.json';
import localeEn from '../../../locales/en/auth/postReg2.json';
import DOMPurify from "dompurify";
import useAuthToken from "../../../hooks/useAuthToken";
import useStateBackend from "../../../hooks/useStateBackend";

const PostRegScreen2 = () => {
    const navigate = useNavigate();
    const token = useAuthToken();
    const backEndState = useStateBackend();

    const handleConnect = () => {
        navigate('/connect');
    }

    const handleSkip = () => {
        navigate('/myStations');
    }

    return (
        <div className={styles.container}>
            <div className={styles.section}>
                <h1 className={styles.titleText}>{localStorage.getItem('language') === 'ru' ? localeRu.connect_station : localeEn.connect_station}
                    <br />{localStorage.getItem('language') === 'ru' ? localeRu.connect_station2 : localeEn.connect_station2} </h1><br />
                <p>{localStorage.getItem('language') === 'ru' ? localeRu.connect_info : localeEn.connect_info}<br />
                    {localStorage.getItem('language') === 'ru' ? localeRu.connect_info2 : localeEn.connect_info2}</p>
            </div>
            <div className={styles.sectionButton}>
                <button
                    type="button"
                    onClick={handleSkip}
                    className={styles.submitButtonSkip}
                >
                    <p className={styles.textButtons}>{localStorage.getItem('language') === 'ru' ? localeRu.skip : localeEn.skip}</p>
                </button>

                <button
                    type="button"
                    onClick={handleConnect}
                    className={styles.submitButtonConnect}
                >
                    <p className={styles.textButtons}>{localStorage.getItem('language') === 'ru' ? localeRu.connect : localeEn.connect}</p>
                    <img src={mobileSignal} className={styles.mobileSignal} alt='signal' />
                </button>

            </div>
        </div>
    );
};

export default PostRegScreen2;
