import React, { useEffect, useState } from 'react';
import styles from './HelpPage.module.css';
import { checkToken } from "../../Utils/TokenService";
import tgIcon from '../../../img/tgLogo.png';
import phoneIcon from '../../../img/phoneIcon.png';
import emailIcon from '../../../img/emailIcon.png';
import { useNavigate } from 'react-router-dom';
import Header from '../../Utils/Header/Header';
import localeRu from '../../../locales/ru/profile/help.json';
import localeEn from '../../../locales/en/profile/help.json';
import useAuthToken from "../../../hooks/useAuthToken";
import useStateBackend from "../../../hooks/useStateBackend";

const HelpPage = () => {
    const navigate = useNavigate();
    let lang = localStorage.getItem('language') === 'ru';

    useAuthToken();
    useStateBackend();

    const handleTgBot = () => {
        window.open("https://t.me/PSS_Support_ChargStations_bot", "_blank");
    };

    // const handlePhone = () => {
    //     window.location.href = "tel:88005006290";
    // };
    const handlePhone = () => {
        if (window.Telegram && window.Telegram.WebApp) {
            window.Telegram.WebApp.showConfirm(lang ? "Позвонить по номеру 88005006290?" : "Call the number 88005006290?", (confirmed) => {
                if (confirmed) {
                    window.open("tel:88005006290", "_blank"); // Альтернативный вариант
                }
            });
        } else {
            window.open("tel:88005006290", "_self");
        }
    };

    // const handleEmail = () => {
    //     window.location.href = "mailto:support@zs-pss.ru";
    // };

    if (typeof window.Telegram !== "undefined" && 
        typeof window.Telegram.WebApp !== "undefined" &&
        /Telegram/i.test(window.navigator.userAgent)) console.log("True")
        
    const handleEmail = () => {
        if (window.Telegram && window.Telegram.WebApp) {
            window.Telegram.WebApp.showConfirm(lang ?  "Написать на support@zs-pss.ru?" : "Write to support@zs-pss.ru?", (confirmed) => {
                if (confirmed) {
                    navigator.clipboard.writeText("support@zs-pss.ru");
                    window.Telegram.WebApp.showAlert(lang ? "Email скопирован! Вставьте его в почтовый клиент." : "Email copied! Paste it into your mail client.");
                }
            });
        } else {
            window.location.href = "mailto:support@zs-pss.ru";
        }
    };

    return (
        <div className={styles.container}>
             <Header />
            <div className={styles.sectionTitle}>
                <p className={styles.titleText}>{lang ? localeRu.help : localeEn.help}</p>
            </div>
            <div className={styles.mainSection}>
                <div
                    className={styles.card}
                    onClick={handleTgBot}
                >
                    <p className={styles.text}>
                        <img src={tgIcon} alt="tgIcon" className={styles.icon} />
                        {lang ? localeRu.tgBot : localeEn.tgBot}
                    </p>
                </div>
                <div
                    className={styles.card}
                    onClick={handlePhone}
                >
                    <p className={styles.text}>
                        <img src={phoneIcon} alt="phoneIcon" className={styles.icon} />
                        {lang ? localeRu.call : localeEn.call}
                    </p>
                </div>
                <div
                    className={styles.card}
                    onClick={handleEmail}
                >
                    <p className={styles.text}>
                        <img src={emailIcon} alt="emailIcon" className={styles.icon} />
                        {lang ? localeRu.email : localeEn.email}
                    </p>
                </div>
            </div>
            <div className={styles.bottomSection}>
                E-mail: support@zs-pss.ru <br/> {lang ? "Телефон" : "Phone"}: 88005006290
            </div>
        </div>
    );
}

export default HelpPage;