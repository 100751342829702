import React, { useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import { fetchProfile } from "./profileService";
import { checkToken } from "../../Utils/TokenService";
import config from "../../../settings/config";
import useAuthToken from "../../../hooks/useAuthToken";
import useStateBackend from "../../../hooks/useStateBackend";

const CheckProfile = () => {
    const navigate = useNavigate();
    const token = useAuthToken();
    useStateBackend();

    useEffect(() => {
        const fetchData = async () => {
            const profile = await fetchProfile(token);
            if (profile) { // Если есть профиль,
                if (!profile.name) { //но нет имени
                    // getAdditionalProfileParams();
                    navigate('/regform');
                } else if (localStorage.getItem('vkloginprocess')) {
                    getAdditionalProfileParams();
                    navigate('/myStations', { state: { name: profile.name } })
                } else {
                    localStorage.setItem("avatar", profile.avatar);
                    localStorage.setItem("name", profile.name);
                    navigate('/myStations', { state: { name: profile.name } })
                }
            } else {
                // TODO тут ошибка подумать что делать, куда отправлять пользовтеля
            }
        }
        fetchData();
    }, []);

    async function getAdditionalProfileParams() {
        let userId = '';
        if (window.Telegram?.WebApp) {
            window.Telegram.WebApp.ready();
            const user = window.Telegram.WebApp.initDataUnsafe?.user;
            if (user && user.id) {
                userId = user.id;
            }
        }
        let userLanguage = navigator.language || navigator.languages[0];
        let userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        try {
            const response = await fetch(`${config.app.host}/api/v1/user/timezone`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    timezone: userTimeZone,
                    lang: userLanguage.substring(0, 2),
                    tgUserId: userId,
                }),
            });

            if (!response.ok) {
                throw new Error(response.status + ': ' + response.statusText);
            }
            return await response.json();
        } catch (error) {
            throw new Error(error.message);
        }
    }

    return (
        <></>
    );



}

export default CheckProfile;
