import React, { useEffect, useState } from 'react';
import useStateBackend from "../../hooks/useStateBackend";
import styles from './WaitingPage.module.css';

const WaitingPage = () => {
    useStateBackend();
    const lang = localStorage.getItem('language') === 'ru';


    return (
        <div className={styles.container}>
            <p className={styles.titleText}>{lang ? "Произошла ошибка" : "Error"} </p>
            <p className={styles.mainText}> {lang ? "Нет связи с сервером, " : "No connection to the server, "}<br />
                {lang ? "проверьте своё интернет соединение." : "check your internet connection."}</p>
        </div>
    );
}

export default WaitingPage;