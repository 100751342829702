import { AuthProvider } from "./context/AuthContext";
import 'bootstrap/dist/css/bootstrap.min.css';
import { HashRouter, Route, Routes, Navigate, useNavigate, useLocation } from 'react-router-dom';
import RegistrationStep2 from "./component/Authorization/Step2/RegistrationStep2";
import RegistrationStep3 from "./component/Authorization/Step3/RegistrationStep3";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import PinCodeScreen from "./component/Authorization/InputPinCode/PinCodeScreen";
import PinCodeOffer from "./component/Authorization/PinOffer/Step1/PinCodeOffer";
import PinCodeOffer2 from "./component/Authorization/PinOffer/Step2/PinCodeOffer2";
import { useState, useEffect } from "react";
import NStartScreen from "./component/Authorization/StartScreen/NStartScreen";
import RegistrationForm from "./component/Authorization/RegistrationForm/RegistrationForm";
import PostRegScreen from "./component/Authorization/PostRegScreen/PostRegScreen";
import PostRegScreen2 from "./component/Authorization/PostRegScreen2/PostRegScreen2";
import ConnectStation from "./component/Stations/ConnectStation/ConnectStation";
import ConnectStation2 from "./component/Stations/ConnectStation2/ConnectStation2";
import StationConnectComplete from "./component/Stations/StationConnectComplete/StationConnectComplete";
import MyStations from "./component/Stations/MyStations/MyStations";
import StationControl from "./component/Stations/StationControl/StationControl";
import StationSettings from "./component/Stations/StationSettings/StationSettings";
import StationSchedule from "./component/Stations/StationSchedule/StationSchedule";
import Profile from "./component/Profile/Profile/Profile";
import ProfileEdit from "./component/Profile/ProfileEdit/ProfileEdit";
import HistoryCharging from "./component/Profile/HistoryCharging/HistoryCharging";
import AboutApp from "./component/Profile/AboutApp/AboutApp";
import HelpPage from "./component/Profile/HelpPage/HelpPage";
import Language from "./component/Profile/Language/Language";
import StationScheduleEdit from "./component/Stations/StationSchedule/StationScheduleEdit";
import DeveloperSettings from "./component/Profile/DeveloperSettings/DeveloperSettings";
import CheckProfile from "./component/Authorization/RegistrationForm/CheckProfile";
import WaitingPage from "./component/Utils/WaitingPage";
import StationShare from "./component/Stations/StationShare/StationShare";

function App() {
    const [title, setTitle] = useState("PSS Charge"); // Состояние для заголовка

    useEffect(() => {
        if (window.Telegram?.WebApp?.disableVerticalSwipes) {
          window.Telegram.WebApp.disableVerticalSwipes();
        }
      }, []);

    return (
        <AuthProvider>
            <HashRouter>
                <Routes>
                    <Route path="/start" element={<NStartScreen setTitle={setTitle} />} />
                    <Route path="/step2" element={<RegistrationStep2 setTitle={setTitle} />} />
                    <Route path="/step3" element={<RegistrationStep3 setTitle={setTitle} />} />
                    <Route path="/pin" element={<PinCodeScreen setTitle={setTitle} />} />
                    <Route path="/pinoffer" element={<PinCodeOffer setTitle={setTitle} />} />
                    <Route path="/pinoffer2" element={<PinCodeOffer2 setTitle={setTitle} />} />
                    <Route path="/checkProfile" element={<CheckProfile />} />
                    <Route path="/regform" element={<RegistrationForm />} />
                    <Route path="/postreg" element={<PostRegScreen />} />
                    <Route path="/postreg2" element={<PostRegScreen2 />} />
                    <Route path="/connect" element={<ConnectStation />} />
                    <Route path="/connect2" element={<ConnectStation2 />} />
                    <Route path="/connectComplete" element={<StationConnectComplete />} />
                    <Route path="/myStations" element={<MyStations />} />
                    <Route path="/stationControl" element={<StationControl />} />
                    <Route path="/stationSettings" element={<StationSettings />} />
                    <Route path="/stationSchedule" element={<StationSchedule />} />
                    <Route path="/stationScheduleEdit" element={<StationScheduleEdit />} />
                    <Route path="/stationShare" element={<StationShare />} />
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/editProfile" element={<ProfileEdit />} />
                    <Route path="/history" element={<HistoryCharging />} />
                    <Route path="/about" element={<AboutApp />} />
                    <Route path="/help" element={<HelpPage />} />
                    <Route path="/language" element={<Language />} />
                    <Route path="/secret" element={<DeveloperSettings />} />
                    <Route path="/waiting" element={<WaitingPage />} />
                    <Route path="*" element={<Navigate to="/start" replace />} />
                </Routes>
            </HashRouter>
            <ToastContainer
                position="top-center" // Устанавливаем позицию уведомлений
                autoClose={3000} // Автоматическое закрытие через 3 секунды
                hideProgressBar // Скрываем прогресс-бар
                newestOnTop // Новые уведомления появляются сверху
                closeOnClick // Закрывать уведомления по клику
                rtl={false} // Направление текста (слева направо)
                pauseOnFocusLoss // Пауза при потере фокуса
                draggable // Возможность перетаскивать уведомления
                pauseOnHover // Пауза при наведении
            />
        </AuthProvider>
    );
}

export default App;
