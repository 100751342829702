import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Language.module.css';
import flagRus from '../../../img/FlagRussia.png'; // Импортируйте изображения флагов
import flagGb from '../../../img/FlagUnitedKingdom.png';
// import flagDe from '../../../img/flagDe.svg'; // Флаг для немецкого языка
// import flagFr from '../../../img/flagFr.svg'; // Флаг для французского языка
import { checkToken } from "../../Utils/TokenService";
import Header from '../../Utils/Header/Header';
import config from '../../../settings/config';
import { toast } from "react-toastify";
import useAuthToken from "../../../hooks/useAuthToken";
import useStateBackend from "../../../hooks/useStateBackend";

const Language = () => {
    const navigate = useNavigate();
    const [user, setUser] = useState();
    const [language, setLanguage] = useState(localStorage.getItem('language'));
    const titleLanguage = localStorage.getItem('language') === 'ru' ? "Язык" : "Language";
    let lang = localStorage.getItem('language') === 'ru';

    // Массив языков
    const languages = [
        { code: 'ru', name: 'Русский', flag: flagRus },
        { code: 'en', name: 'English', flag: flagGb },
        // { code: 'german', name: 'Deutsch', flag: flagDe },
        // { code: 'french', name: 'Français', flag: flagFr },
    ];

    // Обработчик выбора языка
    const handleLanguageChange = (code) => {   
        localStorage.setItem('language', code);     
        setLanguage(code);
        const updatedUser = { ...user, lang: code };
        setUser(updatedUser);
        requestEditUser(updatedUser);
    };

    const token = useAuthToken();
    useStateBackend();

    useEffect(() => {
        requestUser();
    }, [token, navigate]);

    async function requestUser() {
        try {
            const response = await fetch(`${config.app.host}/api/v1/user/getprofile`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('m_token')}`,
                },
            });

            const responseData = await response.json();
            setUser(responseData);
            setLanguage(responseData?.lang.substring(0, 2));

            if (!response.ok) {
                const errorMessage = responseData?.message || 'Неизвестная ошибка';
                // toast.error('Ошибка: ' + errorMessage, {
                //     position: "top-center",
                //     autoClose: 3000
                // }
                // );
            }
        } catch (error) {
        }
    }

    async function requestEditUser(updateUser) {
        try {
            const response = await fetch(`${config.app.host}/api/v1/user/profile`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('m_token')}`,
                },
                body: JSON.stringify(updateUser),
            });
            const responseData = await response.json();
            if (!response.ok) {
                const errorMessage = responseData?.message || 'Неизвестная ошибка';
                toast.error(lang ? 'Ошибка: ' + errorMessage : 'Error: ' + errorMessage , {
                    position: "top-center",
                    autoClose: 3000
                }
                );
            } else {
                // toast.success('Ваши данные успешно сохранены', {
                //     position: "top-center",
                //     autoClose: 3000
                // }
                // );
            }
        } catch (error) {
        }
    }

    return (
        <div className={styles.container}>
            <Header />
            <div className={styles.sectionTitle}>
                <p className={styles.titleText}>{titleLanguage}</p>
            </div>
            <div className={styles.mainSection}>
                {/* Динамический рендеринг кнопок выбора языка */}
                {languages.map((lang) => (
                    <div
                        key={lang.code}
                        className={styles.languageCard}
                        onClick={() => handleLanguageChange(lang.code)}
                    >
                        <p className={styles.text}>
                            <img src={lang.flag} alt={`flag-${lang.code}`} className={styles.icon} />
                            {lang.name}
                        </p>
                        <div className={styles.point}>
                            <div
                                className={language === lang.code ? styles.getPoint : styles.setPoint}
                            ></div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Language;