import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styles from './StationControl.module.css';
import { useNavigate } from "react-router-dom";
import lightning from "../../../img/ligthning.svg"
import config from '../../../settings/config';
import { toast } from "react-toastify";
import schedule from "../../../img/schedule.svg"
import settings from "../../../img/settings.svg"
import phase from "../../../img/phase.svg"
import powerIcon from "../../../img/powerIcon.svg"
import timeIcon from "../../../img/timeIcon.svg"
import consumedEnergyIcon from "../../../img/consumedEnergyIcon.svg"
import infoIcon from "../../../img/info.svg"
import openIcon from "../../../img/openIcon.svg"
import classNames from 'classnames';
import { motion } from "framer-motion";
import Header from '../../Utils/Header/Header';
import TelegramHeader from '../../Utils/TelegramHeader';
import localeRu from '../../../locales/ru/stations/stationControl.json';
import localeEn from '../../../locales/en/stations/stationControl.json';
import DOMPurify from "dompurify";
import useAuthToken from "../../../hooks/useAuthToken";
import useStateBackend from "../../../hooks/useStateBackend";

const StationControl = () => {
    let consLog = false;
    const slaveId = 1;
    const navigate = useNavigate();
    const location = useLocation();
    const stationSn = location.state?.station.sn || localStorage.getItem("stationSn");
    const [station, setStation] = useState();
    const [connector, setConnector] = useState();
    const [status, setStatus] = useState();
    const [buttonText, setButtonText] = useState('Начать зарядку');
    const [backgroundNumber, setBackgroundNumber] = useState(0);
    const [timeTimer, setTime] = useState();
    const [finalTime, setFinalTime] = useState(0);
    const [isRunning, setIsRunning] = useState(false);
    const [readStatus, setReadStatus] = useState(true);
    const [fullConsumedEnergy, setFullConsumedEnergy] = useState(0);
    const [counter, setCounter] = useState(90);
    const [isRunningCounter, setIsRunningCounter] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isAvailableStartButton, setAvailableStartButton] = useState(false);
    const [isPreparingStartButton, setPreparingStartButton] = useState(false);
    const [isFinishingStartButton, setFinishingStartButton] = useState(false);
    const [initialTimeSet, setInitialTimeSet] = useState(false);
    const toggleModal = () => {
        setIsModalOpen((prev) => !prev);
    };
    const fillPercentage = Math.min((connector?.pm / station?.powerCp) * 100, 100);
    let lang = localStorage.getItem('language') === 'ru';
    const [showErrorText, setShowErrorText] = useState(lang ? localeRu["0x6_0x0"] : localeEn["0x6_0x0"]);
    const token = useAuthToken();
    useStateBackend();

    useEffect(() => {
        let interval;
        if (isRunningCounter) {
            interval = setInterval(() => {
                setCounter((prevCounter) => {
                    if (prevCounter > 0) {
                        return prevCounter - 1;
                    } else {
                        clearInterval(interval);
                        return 0;
                    }
                });
            }, 1000);
        }
        return () => clearInterval(interval);
    }, [isRunningCounter]);

    useEffect(() => {
        const replaceText = `${connector?.error}`.trim().replace(' ', '_');
        setShowErrorText(lang ? localeRu[replaceText] : localeEn[replaceText]);
    }, [connector?.error, lang]);

    useEffect(() => {
        if (!initialTimeSet && connector?.chargingTime !== undefined && connector?.chargingTime !== 0) {
            setTime(connector?.chargingTime);
            setInitialTimeSet(true);
        }
    }, [connector?.chargingTime, initialTimeSet]);

    useEffect(() => {
        let interval;
        if (isRunning) {
            interval = setInterval(() => {
                setTime((prevTime) => prevTime + 1);
            }, 1000);
        }
        return () => clearInterval(interval);
    }, [isRunning]);

    const formatTime = (seconds) => {
        if (seconds == null) {
            return "00:00:00";
        }
        const hours = Math.floor(seconds / 3600);
        const mins = Math.floor((seconds % 3600) / 60);
        const secs = seconds % 60;
        return `${String(hours).padStart(2, "0")}:${String(mins).padStart(2, "0")}:${String(secs).padStart(2, "0")}`;
    };

    const formatAmperege = (amperage) => {
        if (!amperage) {
            return "0.0";
        }
        const parseAmpere = (amperage * 1).toFixed(1);
        return parseAmpere;
    };

    const formatPower = (power) => {
        if (!power) {
            return "0.00";
        }
        const parsePower = (power / 100).toFixed(2);
        return parsePower;
    };

    const formatPowerRound = (power) => {
        if (!power) {
            return "0.0";
        }
        const parsePower = (power / 100).toFixed(1);
        if (parsePower > 10) {
            return Math.round(parsePower);
        }
        return parsePower;
    };

    const formatPowerBattery = (power) => {
        if (!power) {
            return "0.0";
        }
        const parsePower = (power * 1).toFixed(1);
        return parsePower;
    };

    const formatStatus = (status) => {
        switch (status) {
            case 'Preparing':
                return lang ? 'Ожидание' : 'Preparing';
            case 'Available':
                return lang ? 'Свободно' : 'Available';
            case 'Faulted':
                return lang ? 'Ошибка' : 'Error';
            case 'Finishing':
                return lang ? 'Завершено' : 'Finishing';
            case 'Charging':
                return lang ? 'Зарядка' : 'Charging';
            default:
                return lang ? 'Нет связи' : "No connection"
        }
    };

    const getStatusStyle = (status) => {
        switch (status) {
            case 'Preparing':
                return styles.statusLedPreparing
            case 'Charging':
                return styles.statusLedCharging;
            default:
                return styles.statusLedAvailable;
        }
    };

    const getCircleStyle = (status) => {
        switch (status) {
            case 'Faulted':
                return styles.circleFaulted;
            case 'Preparing':
                return styles.circlePreparing;
            case 'Charging':
                return styles.circleCharging;
            default:
                return styles.circle;
        }
    };
    const getInnerCircleStyle = (status) => {
        switch (status) {
            case 'Preparing':
                return styles.innercircleRotate;
            case 'Charging':
                return styles.innercircleRotate;
            case 'Faulted':
                return styles.innercircleRotate;
            default:
                return styles.innercircle;
        }
    };

    const getStyleButton = (status) => {
        switch (status) {
            case 'Preparing':
                if (isPreparingStartButton) {
                    return styles.holdStartCharging;
                } else if (isAvailableStartButton) {
                    return styles.holdStartCharging;
                } else if (isFinishingStartButton) {
                    return styles.holdStartCharging;
                } else {
                    if (station.autoCharge) {
                        return styles.holdStartCharging;
                    } else {
                        return styles.submitStartCharging;
                    }
                }
            case 'Available':
                if (isAvailableStartButton) {
                    return styles.holdStartCharging;
                } else if (isFinishingStartButton) {
                    return styles.holdStartCharging;
                } else {
                    if (station.autoCharge) {
                        return styles.holdStartCharging;
                    } else {
                        return styles.submitStartCharging;
                    }
                }
            case 'Faulted':
                return styles.holdStartCharging;
            case 'Finishing':
                if (isFinishingStartButton) {
                    return styles.holdStartCharging;
                } else {
                    return styles.submitStartCharging;
                }
            default:
                return styles.submitStartCharging;
        }
    };

    useEffect(() => {
        getCircleStyle(status);
        getInnerCircleStyle(status);
        getStyleButton(status);
        if (counter < 1) {
            clearAllButtons();
        }
    }, []);

    useEffect(() => {
        switch (status) {
            case 'Faulted':
                lang ? setButtonText('Восстановление коннектера') : setButtonText('Repair connector');
                setBackgroundNumber('')
                setIsRunning(false)
                break;
            case 'Finishing':
                if (isFinishingStartButton) {
                    lang ? setButtonText(`Ожидание зарядки: ${counter}`) : setButtonText(`Preparing: ${counter}`);
                    break;
                } else {
                    lang ? setButtonText('Повтор зарядки') : setButtonText('Repeat charging');
                    setBackgroundNumber('');
                    break;
                }
            case 'Preparing':
                setTime(0);
                if (isPreparingStartButton) {
                    lang ? setButtonText(`Ожидание зарядки: ${counter}`) : setButtonText(`Preparing: ${counter}`);
                    setBackgroundNumber(counter);
                    break;
                } else if (isAvailableStartButton) {
                    lang ? setButtonText('Ожидание зарядки') : setButtonText('Preparing');
                    setBackgroundNumber(Math.round(formatPower(connector?.consumedEnergy)));
                    setIsRunning(false);
                    break;
                } else if (isFinishingStartButton) {
                    lang ? setButtonText('Ожидание зарядки') : setButtonText('Preparing');
                    setBackgroundNumber(Math.round(formatPower(connector?.consumedEnergy)));
                    setIsRunning(false);
                    break;
                } else {
                    if (station.autoCharge) {
                        lang ? setButtonText('Автостарт') : setButtonText('Autocharging');
                        setBackgroundNumber(Math.round(formatPower(connector?.consumedEnergy)));
                        setIsRunning(false);
                        break;
                    } else {
                        lang ? setButtonText('Начать зарядку') : setButtonText('Start charging');
                        setBackgroundNumber(Math.round(formatPower(connector?.consumedEnergy)));
                        setIsRunning(false);
                        break;
                    }
                }
            case 'Charging':
                lang ? setButtonText('Остановить зарядку') : setButtonText('Stop charging');
                setIsRunning(true);
                setBackgroundNumber(Math.round(formatPower(connector?.consumedEnergy)));
                break;
            case "Available":
                setTime(0);
                if (isAvailableStartButton) {
                    lang ? setButtonText(`Вставьте коннектор: ${counter}`) : setButtonText(`Insert connector: ${counter}`);
                    setBackgroundNumber(counter);
                    break;
                } else if (isFinishingStartButton) {
                    lang ? setButtonText('Ожидание зарядки') : setButtonText('Preparing');
                    setBackgroundNumber(Math.round(formatPower(connector?.consumedEnergy)));
                    setIsRunning(false);
                    break;
                } else {
                    if (station.autoCharge) {
                        lang ? setButtonText('Автостарт') : setButtonText('Autocharging');
                        setBackgroundNumber(Math.round(formatPower(connector?.consumedEnergy)));
                        setIsRunning(false);
                        break;
                    } else {
                        lang ? setButtonText('Начать зарядку') : setButtonText('Start charging');
                        setBackgroundNumber(Math.round(formatPower(connector?.consumedEnergy)));
                        setIsRunning(false);
                        break;
                    }
                }
            default:
                lang ? setButtonText('Начать зарядку') : setButtonText('Start charging');
                setBackgroundNumber(Math.round(formatPower(connector?.consumedEnergy)));
                setIsRunning(false);
                setTime(0);
        }
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${config.app.host}/api/v1/station/info?sn=${stationSn}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
                if (!response.ok) {
                    // toast.error('Ошибка получения данных ' + response.status, {
                    //     position: "top-center",
                    //     autoClose: 3000
                    // });
                }

                if (!response.ok) {
                    const result = await response.json().catch(() => ({})); 
                    const errorMessage = result?.message || `Ошибка: ${response.status}`;

                    if (response.status === 400) {
                        console.warn(`Запрос отклонен сервером: ${errorMessage}`);
                        return;
                    }

                    console.error(`Ошибка получения данных: ${errorMessage}`);
                    return;
                }

                const result = await response.json();

                if (consLog) {
                    console.log(result);
                }

                setStation(result);
                setConnector(result?.connectors[0]);

                if ((status === 'Preparing' && result?.connectors[0].status === 'Available') ||
                    (status === 'Finishing' && result?.connectors[0].status === 'Available') ||
                    (status === 'Faulted' && result?.connectors[0].status === 'Available')
                ) {
                    setAvailableStartButton(false);
                    setPreparingStartButton(false);
                }
                if (status === 'Preparing' && result?.connectors[0].status === 'Finishing') {
                    setFinishingStartButton(false);
                }

                if (status === 'Preparing' && result?.connectors[0].status === 'Charging') {
                    clearAllButtons();
                    setTime(0);
                }

                if (status === 'Finishing' && result?.connectors[0].status === 'Charging') {
                    console.log('Fn -> Ch');
                    clearAllButtons();
                    setTime(0);
                }
                if (status === 'Faulted' && result?.connectors[0].status === 'Charging') {
                    clearAllButtons();
                    setTime(0);
                }

                if (status !== result?.connectors[0].status) {
                    setIsRunningCounter(false);
                    setCounter(90);
                }

                setStatus(result?.connectors[0].status);

                if (connector.status === "Finishing") {
                    setFullConsumedEnergy(connector.consumedEnergy);
                    setFinalTime(connector.chargingTime);
                }
                if (counter < 1) {
                    clearAllButtons();
                }
            } catch (error) {
            } finally {
                if (counter < 1) {
                    clearAllButtons();
                }
            }
        };
        fetchData();
        const intervalId = setInterval(fetchData, 5000);
        return () => clearInterval(intervalId);
    }, [readStatus, fullConsumedEnergy, isRunningCounter, isAvailableStartButton, isPreparingStartButton, counter, finalTime, status]);

    function clearAllButtons() {
        setIsRunningCounter(false);
        setAvailableStartButton(false);
        setPreparingStartButton(false);
        setFinishingStartButton(false);
        setCounter(90);
    }

    async function handleButton() {
        switch (status) {
            case 'Preparing':
                if (isPreparingStartButton) {
                    handleHidden();
                    break;
                } else if (isAvailableStartButton) {
                    handleHidden();
                    break;
                } else if (isFinishingStartButton) {
                    handleHidden();
                } else {
                    if (station.autoCharge) {
                        if (lang) {
                            toast.success(`${localeRu.autoChargeEnabled}`, {
                                position: "top-center",
                                autoClose: 5000,
                            });
                        } else {
                            toast.success(`${localeEn.autoChargeEnabled}`, {
                                position: "top-center",
                                autoClose: 5000,
                            });
                        }
                        break;
                    } else {
                        requestStartCharging();
                        if (lang) {
                            toast.success(`${localeRu.startCharging}`, {
                                position: "top-center",
                                autoClose: 3000,
                            });
                        } else {
                            toast.success(`${localeEn.startCharging}`, {
                                position: "top-center",
                                autoClose: 3000,
                            });
                        }
                        break;
                    }
                }
            case 'Charging':
                requestStopCharging();
                if (lang) {
                    toast.success(`${localeRu.stopCharging}`, {
                        position: "top-center",
                        autoClose: 3000,
                    });
                } else {
                    toast.success(`${localeEn.stopCharging}`, {
                        position: "top-center",
                        autoClose: 3000,
                    });
                }
                break;
            case 'Faulted':
                handleHidden();
                break;
            case 'Finishing':
                if (isFinishingStartButton) {
                    handleHidden();
                    if (lang) {
                        toast.success(`${localeRu.waitForStart}`, {
                            position: "top-center",
                            autoClose: 3000,
                        });
                    } else {
                        toast.success(`${localeEn.waitForStart}`, {
                            position: "top-center",
                            autoClose: 3000,
                        });
                    }
                } else {
                    requestStartCharging();
                    if (lang) {
                        toast.success(`${localeRu.startCharging}`, {
                            position: "top-center",
                            autoClose: 3000,
                        });
                    } else {
                        toast.success(`${localeEn.startCharging}`, {
                            position: "top-center",
                            autoClose: 3000,
                        });
                    }
                }
                break;
            case 'Available':
                if (isAvailableStartButton) {
                    handleHidden();
                } else if (isFinishingStartButton) {
                    handleHidden();
                } else {
                    if (station.autoCharge) {
                        if (lang) {
                            toast.success(`${localeRu.autoChargeEnabled}`, {
                                position: "top-center",
                                autoClose: 5000,
                            });
                        } else {
                            toast.success(`${localeEn.autoChargeEnabled}`, {
                                position: "top-center",
                                autoClose: 5000,
                            });
                        }
                        // toast.success("Включена автозарядка, если хотите сделать старт из приложения выключите автозарядку в настройках станции", {
                        //     position: "top-center",
                        //     autoClose: 5000
                        // });
                    } else {
                        requestStartCharging();
                        if (lang) {
                            toast.success(`${localeRu.connectAndCharge}`, {
                                position: "top-center",
                                autoClose: 3000,
                            });
                        } else {
                            toast.success(`${localeEn.connectAndCharge}`, {
                                position: "top-center",
                                autoClose: 3000,
                            });
                        }
                        // toast.success("Подключите коннектор и зарядка запустится", {
                        //     position: "top-center",
                        //     autoClose: 3000
                        // });
                    }
                }
                break;
            default:
                requestStartCharging();
        }
    }

    async function requestStartCharging() {
        try {
            const response = await fetch(`${config.app.host}/api/v1/station/start?sn=${station.sn}&maxP=1&maxC=1&slaveId=${slaveId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('m_token')}`,
                },
            });

            const responseData = await response.json();

            if (!response.ok) {
                const errorMessage = responseData?.message || 'Неизвестная ошибка';
                // toast.error('Ошибка: ' + errorMessage, {
                //     position: "top-center",
                //     autoClose: 3000
                // }
                // );
            }
        } catch (error) {
        }
        if (status === "Available") {
            setAvailableStartButton(true);
        }
        if (status === "Preparing") {
            setPreparingStartButton(true);
        }
        if (status === "Finishing") {
            setFinishingStartButton(true);
        }
        setIsRunningCounter(true);
    }

    async function requestStopCharging() {
        try {
            const response = await fetch(`${config.app.host}/api/v1/station/stop?sn=${station.sn}&slaveId=${slaveId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('m_token')}`,
                },
            });

            const responseData = await response.json();

            if (!response.ok) {
                const errorMessage = responseData?.message || 'Неизвестная ошибка';
                // toast.error('Ошибка: ' + errorMessage, {
                //     position: "top-center",
                //     autoClose: 3000
                // }
                // );
            }
        } catch (error) {
        }
    }

    const handleHidden = () => {
    }

    const handleFinishing = () => {
        setReadStatus(true);
    }

    const handleSchedule = () => {
        // navigate('/stationSchedule', { state: { station } });
        if (!isAvailableStartButton && !isPreparingStartButton) {
            navigate('/stationSchedule', { state: { station } });
        }

    }

    const handleSettings = () => {
        if (!isAvailableStartButton && !isPreparingStartButton) {
            navigate('/stationSettings', { state: { station } });
        }
    }

    return (
        <div className={styles.container}>
            <TelegramHeader />
            <Header />
            <div className={styles.sectionTitle}>
                <h1 className={styles.titleText}>{station?.nickName}</h1>
                <div
                    className={styles.settingsContainer}
                    onClick={handleSettings}
                >
                    <img src={settings} className={styles.imgSettings} alt='settings' />
                </div>
            </div>
            <div className={styles.main}>
                {status === "Finishing" && (
                    <div className={styles.finishingSection}>
                        <div className={styles.sectionTitle}>
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(
                                        lang
                                            ? localeRu.charging_finished
                                            : localeEn.charging_finished
                                    ),
                                }}
                            />
                        </div>
                        <div className={styles.chargingInfoContainer}>
                            <div className={styles.infoParameter}>
                                <div className={styles.parameterName}>
                                    <p className={styles.textButtons}>{lang ? localeRu.charging_time : localeEn.charging_time}</p>
                                </div>
                                <div className={styles.parameterValue}>
                                    <p className={styles.textButtons}>{formatTime(connector?.chargingTime)}</p>
                                </div>
                            </div>
                            <div className={styles.infoParameter}>
                                <div className={styles.parameterName}>
                                    <p className={styles.textButtons}>{lang ? localeRu.charged : localeEn.charged}</p>
                                </div>
                                <div className={styles.parameterValue}>
                                    <p className={styles.textButtons}>{formatPower(connector?.consumedEnergy)} {lang ? 'кВтч' : 'kWh'}</p>
                                </div>
                            </div>
                            <div className={styles.infoParameter}>
                                <div className={styles.parameterName}>
                                    <p className={styles.textButtons}>{lang ? localeRu.range : localeEn.range} <img
                                        src={infoIcon}
                                        className={styles.imgSettings}
                                        alt='info'
                                        onClick={toggleModal}
                                    /></p>
                                </div>
                                <div className={styles.parameterValue}>
                                    <p className={styles.textButtons}>~ +{((Math.round(formatPower(connector?.consumedEnergy)) / 22) * 100).toFixed(1)} {lang ? "км" : "km"}</p>
                                </div>
                            </div>
                        </div>
                        {/* Модальное окно */}
                        {isModalOpen && (
                            <div className={styles.modalOverlay} onClick={toggleModal}>
                                <div
                                    className={styles.modalContent}
                                    onClick={(e) => e.stopPropagation()} // Отключаем закрытие по клику внутри модалки
                                >
                                    <p className={styles.modalText}>{lang ? localeRu.range_info : localeEn.range_info}</p>
                                    <p className={styles.modalText}>
                                        {lang ? localeRu.range_description : localeEn.range_description}
                                    </p>
                                    <button className={styles.closeButton} onClick={toggleModal}>
                                        {lang ? localeRu.close : localeEn.close}
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                )}

                {status === "Faulted" && (
                    <div className={styles.faultedSection}>
                        <div className={styles.faulted}>
                            <p className={styles.errorText}
                            >{lang ? localeRu.error_occurred : localeEn.error_occurred}</p>
                            {/* <p>{lang ? localeRu.connector_recovery : localeEn.connector_recovery}<br /></p> */}
                            <p
                                className={styles.errorText}
                                dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(
                                        showErrorText
                                    ),
                                }}
                            />
                        </div>
                    </div>)}
                {status !== "Finishing" && status !== "Faulted" && (
                    <div className={styles.section}>
                        <div className={styles.parameters}>
                            <div className={styles.mainParameters}>
                                <div className={styles.paramContainer}>
                                    <div className={styles.iconContainer}>
                                        <img src={phase} className={styles.paramImage} alt='phase A' />
                                    </div>
                                    <div className={styles.textContainer}>
                                        <p className={styles.paramText}>{lang ? localeRu.phase_a : localeEn.phase_a}</p>
                                        <p className={styles.paramText}>{formatAmperege(connector?.currentA)} A / {connector?.voltageA} {lang ? "B" : "V"}</p>
                                    </div>
                                </div>
                                <div className={styles.paramContainer}>
                                    <div className={styles.iconContainer}>
                                        <img src={phase} className={styles.paramImage} alt='phase B' />
                                    </div>
                                    <div className={styles.textContainer}>
                                        <p className={styles.paramText}>{lang ? localeRu.phase_b : localeEn.phase_b}</p>
                                        <p className={styles.paramText}>{formatAmperege(connector?.currentB)} A / {connector?.voltageB} {lang ? "B" : "V"}</p>
                                    </div>
                                </div>
                                <div className={styles.paramContainer}>
                                    <div className={styles.iconContainer}>
                                        <img src={phase} className={styles.paramImage} alt='phase C' />
                                    </div>
                                    <div className={styles.textContainer}>
                                        <p className={styles.paramText}>{lang ? localeRu.phase_c : localeEn.phase_c}</p>
                                        <p className={styles.paramText}>{formatAmperege(connector?.currentC)} A / {connector?.voltageC} {lang ? "B" : "V"}</p>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.mainParameters}>
                                <div className={styles.paramContainer}>
                                    <div className={styles.iconContainer}>
                                        <img src={powerIcon} className={styles.paramImage} alt='power' />
                                    </div>
                                    <div className={styles.textContainer}>
                                        <p className={styles.paramText}>{lang ? localeRu.power : localeEn.power}</p>
                                        <p className={styles.paramText}>{connector?.powerCommon} {lang ? "кBт" : "kW"}
                                        </p>
                                    </div>
                                </div>
                                <div className={styles.paramContainer}>
                                    <div className={styles.iconContainer}>
                                        <img src={timeIcon} className={styles.paramImage} alt='time' />
                                    </div>
                                    <div className={styles.textContainer}>
                                        <p className={styles.paramText}>{lang ? localeRu.time : localeEn.time}</p>
                                        <p className={styles.paramText}>{formatTime(timeTimer)}</p>
                                    </div>
                                </div>
                                <div className={styles.paramContainer}>
                                    <div className={styles.iconContainer}>
                                        <img src={consumedEnergyIcon} className={styles.paramImage} alt='consumedEnergy' />
                                    </div>
                                    <div className={styles.textContainer}>
                                        <p className={styles.paramText}>{lang ? localeRu.consumption : localeEn.consumption}</p>
                                        <p className={styles.paramText}>{formatPower(connector?.consumedEnergy)} {lang ? "кBтч" : "kWh"}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {station?.stationSettings.outerCounter && (
                            <div className={styles.outerCounterContainer}>
                                <div className={styles.outerCounterName}>
                                    <div className={styles.statusSection}>
                                        <div className={styles.statusOuterCounter}>
                                            <div className={getStatusStyle(status)}><div className={styles.statusOuterCounterInner} /> </div>
                                            
                                        </div>
                                        <p> {formatStatus(status)}</p>
                                    </div>
                                    <p className={styles.outerCounterText}>{lang ? localeRu.outer_counter : localeEn.outer_counter} <img src={openIcon} className={styles.paramImage} alt='open' /></p>
                                </div>
                                <div className={styles.outerData}>
                                    <div className={styles.mainParameters}>
                                        <div className={styles.paramContainer}>
                                            <div className={styles.iconContainer}>
                                                <img src={phase} className={styles.paramImage} alt='phase A' />
                                            </div>
                                            <div className={styles.textContainer}>
                                                <p className={styles.paramText}>{lang ? localeRu.phase_a : localeEn.phase_a}</p>
                                                <p className={styles.paramText}>{formatAmperege(connector?.iam)} A / {connector?.uam ? connector?.uam : '0.0'} {lang ? 'B' : 'V'} / {connector?.pam ? connector?.pam : '0'} {lang ? 'кBт' : 'kW'}</p>
                                            </div>
                                        </div>
                                        <div className={styles.paramContainer}>
                                            <div className={styles.iconContainer}>
                                                <img src={phase} className={styles.paramImage} alt='phase B' />
                                            </div>
                                            <div className={styles.textContainer}>
                                                <p className={styles.paramText}>{lang ? localeRu.phase_b : localeEn.phase_b}</p>
                                                <p className={styles.paramText}>{formatAmperege(connector?.ibm)} A / {connector?.ubm ? connector?.ubm : '0.0'} {lang ? 'B' : 'V'} / {connector?.pbm ? connector?.pbm : '0'} {lang ? 'кBт' : 'kW'}</p>
                                            </div>
                                        </div>
                                        <div className={styles.paramContainer}>
                                            <div className={styles.iconContainer}>
                                                <img src={phase} className={styles.paramImage} alt='phase C' />
                                            </div>
                                            <div className={styles.textContainer}>
                                                <p className={styles.paramText}>{lang ? localeRu.phase_c : localeEn.phase_c}</p>
                                                <p className={styles.paramText}>{formatAmperege(connector?.icm)} A / {connector?.ucm ? connector?.ucm : '0.0'} {lang ? 'B' : 'V'} / {connector?.pcm ? connector?.pcm : '0'} {lang ? 'кBт' : 'kW'}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.outerBattery}>
                                        <div className={styles.powerCard}>
                                            <span className={styles.title}>{lang ? localeRu.power : localeEn.power}</span>
                                            <div className={styles.outerContainer}>
                                                <motion.div
                                                    className={styles.fill}
                                                    style={{ height: `${fillPercentage}%` }}
                                                    initial={{ height: 0 }}
                                                    animate={{ height: `${fillPercentage}%` }}
                                                    transition={{ duration: 1 }}
                                                />
                                                <div className={styles.powerText}>
                                                    {formatPowerBattery(connector?.pm)}
                                                    <span className={styles.unit}>kW</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {!station?.stationSettings.outerCounter && (
                            <div
                                class={classNames(styles.circle, getCircleStyle(status))}>
                                <div class={classNames(styles.innercircle, getInnerCircleStyle(status))}>
                                    <span class={styles.text}>{formatPowerRound(connector?.consumedEnergy)}<br />kWh</span>
                                </div>
                            </div>

                        )}
                    </div>
                )}
            </div>

            <div className={styles.sectionButtons}>
                <button
                    type="button"
                    onClick={handleButton}
                    className={classNames(styles.submitStartCharging, getStyleButton(status))}
                >
                    <p className={styles.textButtons}>{buttonText}</p><img src={lightning} className={styles.lightning} alt='lightning' />
                </button>
                <button
                    type="button"
                    onClick={handleSchedule}
                    className={styles.submitSchedule}
                >
                    <p className={styles.textButtons}></p><img src={schedule} className={styles.scheduleImg} alt='schedule' />
                </button>
            </div>
            <div class={styles.backgroundNumber}>{backgroundNumber}</div>
        </div>
    );
}

export default StationControl;