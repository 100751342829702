import React, { useEffect, useState } from 'react';
import styles from './MyStations.module.css';
import { checkToken, hasRole } from "../../Utils/TokenService";
import { useNavigate } from "react-router-dom";
import config from '../../../settings/config';
import { toast } from "react-toastify";
import plusIcon from "../../../img/plus-station.svg"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ministation from "../../../img/Station.png";
import classNames from 'classnames';
import Header from '../../Utils/Header/Header';
import TelegramHeader from '../../Utils/TelegramHeader';
import localeRu from '../../../locales/ru/stations/myStations.json';
import localeEn from '../../../locales/en/stations/myStations.json';
import useAuthToken from "../../../hooks/useAuthToken";
import useStateBackend from "../../../hooks/useStateBackend";
import useLongPress from "../../../hooks/useLongPress";
import CustomModal from '../../Utils/CustomModal';

const MyStations = () => {
    const [stations, setStations] = useState([]);
    // const token = localStorage.getItem('m_token')
    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        beforeChange: (_, next) => {
            setCurrentStation(stations[next]);
        },
    };
    const navigate = useNavigate();
    const [currentStation, setCurrentStation] = useState(null);
    const [showModal, setShowModal] = useState(false);
    let lang = localStorage.getItem('language') === 'ru';
    const [delSt, setDelSt] = useState(null);
    const [delStName, setDelStName] = useState(null);

    const token = useAuthToken();
    useStateBackend();

    const handleLongPress = () => {
        setDelSt(currentStation?.sn);
        setDelStName(currentStation?.nickName);
        setShowModal(true);
    };

    const longPressProps = useLongPress(handleLongPress, 1000); // 1 секунда

    const changeStatusLanguage = (status, isAlive) => {
        switch (status) {
            case 'Preparing':
                if (isAlive) {
                    return lang ? 'Ожидание' : 'Preparing';
                } else {
                    return lang ? 'Нет связи' : "No connection"
                }
            case 'Available':
                if (isAlive) {
                    return lang ? 'Свободно' : 'Available';
                } else {
                    return lang ? 'Нет связи' : "No connection"
                }
            case 'Faulted':
                if (isAlive) {
                    return lang ? 'Ошибка' : 'Error';
                } else {
                    return lang ? 'Нет связи' : "No connection"
                }
            case 'Finishing':
                if (isAlive) {
                    return lang ? 'Завершено' : 'Finishing';
                } else {
                    return lang ? 'Нет связи' : "No connection"
                }
            case 'Charging':
                if (isAlive) {
                    return lang ? 'Зарядка' : 'Charging';
                } else {
                    return lang ? 'Нет связи' : "No connection"
                }
            default:
                if (isAlive) {
                    return lang ? 'Статус' : 'Status';
                } else {
                    return lang ? 'Нет связи' : "No connection"
                }
        }
    };

    const getStyleStatus = (status, isAlive) => {
        if (!isAlive) {
            return styles.statusOff;
        }
        switch (status) {
            case 'Preparing':
                return styles.statusPreparing;
            case 'Available':
                return styles.status;
            case 'Faulted':
                return styles.statusFaulted;
            case 'Finishing':
                return styles.statusFinishing;
            case 'Charging':
                return styles.statusCharging;
            default:
                return styles.status;
        }
    };

    const getStyleAlive = (status) => {
        if (status) {
            return styles.mainSection;
        } else {
            return styles.mainSectionOff;
        }
    };

    const getStyleTypeAlive = (status) => {
        if (status) {
            return styles.paramContainer;
        } else {
            return styles.paramContainerOff;
        }
    };

    useEffect(() => {
        changeStatusLanguage(currentStation?.connectors[0]?.status, currentStation?.isAlive);
        getStyleStatus(currentStation?.connectors[0]?.status, currentStation?.isAlive);
        getStyleAlive(currentStation?.connectors[0]?.status);
        getStyleTypeAlive(currentStation?.connectors[0]?.status);
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${config.app.host}/api/v1/station/list`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
                if (!response.ok) {
                    if (lang) {
                        toast.error(`${localeRu.error} ${response.status}`, {
                            position: "top-center",
                            autoClose: 3000,
                        });
                    } else {
                        toast.error(`${localeEn.error} ${response.status}`, {
                            position: "top-center",
                            autoClose: 3000,
                        });
                    }
                    // toast.error('Ошибка получения данных: ' + response.status, {
                    //     position: "top-center",
                    //     autoClose: 3000
                    // });
                }
                const result = await response.json();
                setStations(result);
            } catch (error) {
                // console.log(error)
                // Обработка ошибок
            }
        };

        fetchData();
        const intervalId = setInterval(fetchData, 5000);
        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        if (stations.length > 0 && !currentStation) {
            setCurrentStation(stations[0]);
        } else if (currentStation) {
            const foundStation = stations.find(station => station.nickName === currentStation.nickName);
            if (foundStation) {
                setCurrentStation(foundStation);
            }
        }
    }, [stations, currentStation]);

    //Отключение вывода контекстного меню
    useEffect(() => {
        const disableContextMenu = (event) => {
            event.preventDefault();
        };

        document.addEventListener("contextmenu", disableContextMenu);

        return () => {
            document.removeEventListener("contextmenu", disableContextMenu);
        };
    }, []);

    const handleConnect = () => {
        navigate('/connect');
    }

    const confirmDeleteStation = async () => {
        // Выполняем логику выхода, например, очищаем токены и перенаправляем на страницу входа
        let mToken = localStorage.getItem('m_token');
        let resp = '';

        if (mToken) {
            try {
                const response = await fetch(`${config.app.host}/api/v1/station/delete/?sn=${delSt}`, {
                    method: 'GET',
                    mode: 'cors',
                    headers: {
                        'Authorization': `Bearer ${mToken}`,
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    // throw new Error('Network response was not ok');
                    if (lang) {
                        toast.error(`${localeRu.networkError}`, {
                            position: "top-center",
                            autoClose: 3000,
                        });
                    } else {
                        toast.error(`${localeEn.networkError}`, {
                            position: "top-center",
                            autoClose: 3000,
                        });
                    }
                }

                resp = await response.json();
                if (resp) {
                    // setShowModal(false);
                    // navigate(0); 
                    window.location.reload();
                }
            } catch (error) {
                // setShowModal(false);
                // navigate(0); // Перенаправляем на страницу входа
                window.location.reload()
            }
            toast.success(lang ? `Станция c серийным номером ${delStName} удалена!` : `Station with serial number ${delStName} has been deleted!`, {
                position: "top-center",
                autoClose: 3000
            });
        }
        // toast.success(
        //     (lang ? localeRu.stationDeleted : localeEn.stationDeleted).replace("{nickName}", delStName),
        //     {
        //         position: "top-center",
        //         autoClose: 3000
        //     }
        // );
        window.location.reload();
        // setShowModal(false);
        // navigate(0); // Перенаправляем на страницу входа
    };

    const handleCardClick = (station) => {
        if (station.isAlive) {
            navigate('/stationControl', { state: { station } });
        } else {
            toast.error('Со станцией нет связи, проверьте интернет соединение', {
                position: "top-center",
                autoClose: 3000
            });
        }
    };

    return (
        <div className={styles.container}>
            <TelegramHeader />
            <Header />
            <div className={styles.section}>
                <h1 className={styles.titleText}>{localStorage.getItem('language') === 'ru' ? localeRu.my_stations : localeEn.my_stations} </h1>
            </div>
            <div className={stations?.length === 0 ? styles.section : styles.hidden}>
                <p className={styles.mainText}>{localStorage.getItem('language') === 'ru' ? localeRu.no_stations : localeEn.no_stations} </p>
            </div>
            <div className={stations?.length > 0 ? classNames(styles.mainSection, getStyleAlive(currentStation?.isAlive)) : styles.hidden}>
                {stations?.length > 1 ? (
                    <Slider {...sliderSettings}>
                        {stations?.map((station, index) => (
                            <div
                                key={index}
                                className={styles.card}
                                onClick={() => handleCardClick(station)}
                                {...(!station.isAlive && longPressProps)}
                            >

                                <div className={styles.information}>
                                    <div className={classNames(styles.status, getStyleStatus(station?.connectors[0]?.status, station.isAlive))}>
                                        <p className={styles.paramText}>{changeStatusLanguage(station?.connectors[0]?.status, station.isAlive)}</p>
                                    </div>
                                    <div className={styles.parameters}>
                                        <div className={classNames(styles.paramContainer, getStyleTypeAlive(station.isAlive))}><p className={styles.paramText}>Type2</p></div>
                                        <div className={classNames(styles.paramContainer, getStyleTypeAlive(station.isAlive))}><p className={styles.paramText}>{station.powerCp}{lang ? "кBт" : "kW"}</p></div>
                                    </div>
                                </div>
                                <div className={styles.imageContainer}>
                                    <img
                                        src={ministation}
                                        className={styles.miniStation}
                                        alt='ministation'
                                        onContextMenu={(e) => e.preventDefault()} />
                                </div>
                                <div className={styles.nickName}>
                                    <p className={styles.textStationName}>{station.nickName}</p>
                                </div>
                            </div>
                        ))}
                    </Slider>
                ) : (
                    <div
                        className={classNames(styles.card, getStyleAlive(stations[0]?.isAlive))}
                        onClick={() => handleCardClick(stations[0])}
                        {...(!stations[0]?.isAlive && longPressProps)}
                    >
                        <div className={styles.information}>
                            <div className={classNames(styles.status, getStyleStatus(stations[0]?.connectors[0]?.status, stations[0]?.isAlive))}>
                                <p className={styles.paramText}>{changeStatusLanguage(stations[0]?.connectors[0]?.status, stations[0]?.isAlive)}</p>
                            </div>
                            <div className={styles.parameters}>
                                <div
                                    className={classNames(styles.paramContainer, getStyleTypeAlive(stations[0]?.isAlive))}
                                ><p className={styles.paramText}>Type2</p></div>
                                <div className={classNames(styles.paramContainer, getStyleTypeAlive(stations[0]?.isAlive))}
                                ><p className={styles.paramText}>{stations[0]?.powerCp}{lang ? "кBт" : "kW"}</p></div>
                            </div>
                        </div>
                        <div className={styles.imageContainer}>
                            <img
                                src={ministation}
                                className={styles.miniStation}
                                alt='ministation'
                                onContextMenu={(e) => e.preventDefault()}
                            />
                        </div>
                        <div className={styles.nickName}>
                            <p className={styles.textStationName}>{stations[0]?.nickName}</p>
                        </div>
                    </div>
                )}
            </div>
            <div className={styles.sectionButtons}>
                <div className={styles.skipContainer}>
                    <button
                        type="button"
                        onClick={handleConnect}
                        className={styles.submitButtonConnect}
                    >
                        <p className={styles.textButtons}>{localStorage.getItem('language') === 'ru' ? localeRu.add_station : localeEn.add_station} </p><img src={plusIcon} className={styles.plusIcon} alt='plus' />
                    </button>
                </div>
            </div>
            <CustomModal
                show={showModal}
                onClose={() => setShowModal(false)}
                onConfirm={confirmDeleteStation}
                textMsg={lang ? localeRu.deleteConfirmTitle : localeEn.deleteConfirmTitle}
                textQuestions={lang ? localeRu.deleteConfirmText : localeEn.deleteConfirmText}
                yes={lang ? "Да" : "Yes"}
                no={lang ? "Нет" : "No"}
            />
        </div>
    );
}

export default MyStations;