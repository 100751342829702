import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {checkToken} from "./tokenService";

const useAuthToken = () => {
    const navigate = useNavigate();
    const [token, setToken] = useState(() => localStorage.getItem("m_token"));

    useEffect(() => {
        const validateToken = () => {
            const newToken = localStorage.getItem("m_token");
            setToken(newToken);

            if (checkToken(newToken)) {
                localStorage.removeItem("m_token");
                navigate("/step2");
            }
        };

        // Проверяем токен при первом рендере
        validateToken();

        // Добавляем слушатель событий изменения `localStorage`
        window.addEventListener("storage", validateToken);
        return () => window.removeEventListener("storage", validateToken);
    }, [navigate]);

    return token;
};

export default useAuthToken;