import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styles from './StationSettings.module.css';
import { checkToken } from "../../Utils/TokenService";
import { useNavigate } from "react-router-dom";
import config from '../../../settings/config';
import { toast } from "react-toastify";
import CustomModal from '../../Utils/CustomModal';
import classNames from 'classnames';
import Header from '../../Utils/Header/Header';
import localeRu from '../../../locales/ru/stations/stationSettings.json';
import localeEn from '../../../locales/en/stations/stationSettings.json';
import TimezoneSelect from "react-timezone-select";
import useAuthToken from "../../../hooks/useAuthToken";
import useStateBackend from "../../../hooks/useStateBackend";

const StationSettings = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [station, setStation] = useState(location.state?.station);
    const [stationName, setStationName] = useState(location.state?.station.nickName);
    const [stationPower, setStationPower] = useState(location.state?.station.powerCp);
    const [showModal, setShowModal] = useState(false);
    const [maxPower, setMaxPower] = useState(Number(location.state?.station.powerCp) < Number(location.state?.station.maxPower) ? location.state?.station.powerCp : location.state?.station.maxPower);
    const [autoCharge, setAutoCharge] = useState(location.state?.station.autoCharge || false);
    const [outerCounter, setOuterCounter] = useState(location.state?.station.stationSettings.outerCounter || false);
    const [isNotification, setIsNotification] = useState(location.state?.station.stationSettings.isNotification || false);
    const [selectedTimezone, setSelectedTimezone] = useState(location.state?.station.timeZone ||
        Intl.DateTimeFormat().resolvedOptions().timeZone
    );
    let lang = localStorage.getItem('language') === 'ru';

    const changeScale = (maxPower) => {
        return (maxPower - 1) / 4;
    };

    const getStyleButton = (status) => {
        if (status) {
            return styles.autoChargeSettingOn;
        } else {
            return styles.autoChargeSetting;
        }
    }

    const scaleStep = changeScale(stationPower);
    // const station = location.state?.station;  // Получаем объект станции из state
    const slaveId = 1;
    const [stationUpdate, setStationUpdate] = useState(station.connectors[0].update);

    const token = useAuthToken();
    useStateBackend();

    async function requestSettings() {
        try {
            // Создаем объект с данными для отправки
            const requestBody = {
                sn: station.sn,
                nickName: stationName,
                maxPower: maxPower,
                slaveId: slaveId,
                autoCharge: autoCharge,
                outerCounter: outerCounter,
                isNotification: isNotification,
                timeZone: selectedTimezone || location.state?.station.timeZone,
            };

            const response = await fetch(`${config.app.host}/api/v1/station/settings/edit`, {
                method: 'POST', // Используем метод POST
                headers: {
                    'Content-Type': 'application/json', // Указываем, что отправляем JSON
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(requestBody), // Преобразуем объект в JSON
            });

            const responseData = await response.json();

            if (!response.ok) {
                // const errorMessage = responseData?.message || 'Неизвестная ошибка';
                // toast.error('Ошибка: ' + errorMessage, {
                //     position: "top-center",
                //     autoClose: 3000
                // });
                toast.error(lang ? localeRu.unknownError : localeEn.unknownError, {
                    position: "top-center",
                    autoClose: 3000,
                });
            }
            toast.success(lang ? localeRu.stationSettingsSaved : localeEn.stationSettingsSaved, {
                position: "top-center",
                autoClose: 3000
            });
            // toast.success('Настройки станции сохранены!', {
            //     position: "top-center",
            //     autoClose: 3000
            // });
        } catch (error) {
            // console.error('Ошибка при отправке запроса:', error);
        }
        navigate(-1);
    }

    const confirmDeleteStation = async () => {
        // Выполняем логику выхода, например, очищаем токены и перенаправляем на страницу входа
        let resp = '';
        if (token) {
            try {
                const response = await fetch(`${config.app.host}/api/v1/station/delete/?sn=${station.sn}`, {
                    method: 'GET',
                    mode: 'cors',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    // throw new Error('Network response was not ok');
                    if (lang) {
                        toast.error(`${localeRu.networkError}`, {
                            position: "top-center",
                            autoClose: 3000,
                        });
                    } else {
                        toast.error(`${localeEn.networkError}`, {
                            position: "top-center",
                            autoClose: 3000,
                        });
                    }
                }

                resp = await response.json();
                if (resp.error) {
                    navigate('/myStations'); // Перенаправляем на страницу входа
                }

            } catch (error) {
                navigate('/myStations'); // Перенаправляем на страницу входа
            }
            toast.success(
                (lang ? localeRu.stationDeleted : localeEn.stationDeleted).replace("{nickName}", station.nickName),
                {
                    position: "top-center",
                    autoClose: 3000
                }
            );
            // toast.success(`Станция ${station.nickName} удалена!`, {
            //     position: "top-center",
            //     autoClose: 3000
            // });
        }
        navigate('/myStations'); // Перенаправляем на страницу входа
    };

    const updateStation = async () => {
        // Выполняем логику выхода, например, очищаем токены и перенаправляем на страницу входа
        let resp = '';

        if (token) {
            try {
                const response = await fetch(`${config.app.host}/api/v1/station/update/?sn=${station.sn}&slaveId=${slaveId}`, {
                    method: 'GET',
                    mode: 'cors',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    // throw new Error('Network response was not ok');
                    if (lang) {
                        toast.error(`${localeRu.networkError}`, {
                            position: "top-center",
                            autoClose: 3000,
                        });
                    } else {
                        toast.error(`${localeEn.networkError}`, {
                            position: "top-center",
                            autoClose: 3000,
                        });
                    }
                }
                resp = await response.json();

                setStationUpdate(resp.result);

            } catch (error) {
            }
            toast.success(lang ? localeRu.startUpdate : localeEn.startUpdate, {
                position: "top-center",
                autoClose: 3000
            });
        }
    };

    const handleChangeAutoCharge = () => {
        setAutoCharge(!autoCharge);
    }

    const handleChangeInnerCounter = () => {
        setOuterCounter(!outerCounter);
    }

    const handleChangeIsNotification = () => {
        setIsNotification(!isNotification);
    }

    const handleSettings = () => {
        requestSettings();
    }

    const handleUpdate = () => {
        updateStation();
    }

    const handleLogOut = () => {
        setShowModal(true);
    };

    const handleShare = () => {
        navigate('/stationShare', { state: { station } });
    }

    return (
        <div className={styles.container}>
            <Header />
            <div className={styles.sectionTitle}>
                <p className={styles.titleText}>{lang ? localeRu.titleName : localeEn.titleName}</p>
            </div>
            <div className={styles.mainSection}>
                <div className={styles.parameter}>
                    <p className={styles.paramText}>{lang ? localeRu.stationNameLabel : localeEn.stationNameLabel}</p>
                </div>
                <input
                    type="text"
                    className={styles.card}
                    value={stationName}
                    maxLength={20}
                    onChange={(e) => {
                        if (e.target.value.length <= 20) {
                            setStationName(e.target.value);
                        }
                    }}
                />
                <div className={styles.parameter}>
                    <p className={styles.paramText}>{lang ? localeRu.maxPowerLabel : localeEn.maxPowerLabel}</p>
                </div>
                <div className={styles.maxPowerContainer}>
                    <div className={styles.sliderContainer}>
                        <input
                            type="range"
                            min="1"
                            max={stationPower}
                            step="0.1"
                            value={maxPower}
                            onChange={(e) => setMaxPower(parseFloat(e.target.value))}
                            className={styles.slider}
                            style={{
                                background: `linear-gradient(to right, rgb(80, 96, 110) ${((maxPower - 1) / (stationPower - 1)) * 100}%, #ccc ${((maxPower - 1) / (stationPower - 1)) * 100}%)`
                            }}
                        />
                        <div className={styles.scale}>
                        {[...Array(Math.ceil((stationPower - 1) / scaleStep) + 1)].map((_, index) => {
                            const value = (index * scaleStep + 1).toFixed(1); // Начинаем с 1, сохраняем дробные значения
                            if (parseFloat(value) > stationPower) return null; // Не добавляем деления выше максимальной мощности
                            return (
                                <div key={value} className={styles.scaleMark}>
                                    <span className={styles.scaleLabel}>{value}</span>
                                </div>
                            );
                        })}
                    </div>
                    </div>
                    <div className={styles.currentValue}>
                        <p className={styles.textMaxPower}>{maxPower} {lang ? "кВт" : "kW"}</p>
                    </div>
                </div>
                <div className={styles.autochargeContainer}>
                    <p className={styles.textAutoCharge}>{lang ? localeRu.autoChargeLabel : localeEn.autoChargeLabel}</p>
                    <div
                        class={classNames(styles.autoChargeSetting, getStyleButton(autoCharge))}

                        onClick={handleChangeAutoCharge}
                    >
                        <div className={styles.autoChargeSettingElement}></div>
                    </div>
                </div>
                <div className={styles.autochargeContainer}>
                    <p className={styles.textAutoCharge}>{lang ? localeRu.outerCounterLabel : localeEn.outerCounterLabel}</p>
                    <div
                        class={classNames(styles.autoChargeSetting, getStyleButton(outerCounter))}

                        onClick={handleChangeInnerCounter}
                    >
                        <div className={styles.autoChargeSettingElement}></div>
                    </div>
                </div>
                <div className={styles.autochargeContainer}>
                    <p className={styles.textAutoCharge}>{lang ? localeRu.notificationLabel : localeEn.notificationLabel}</p>
                    <div
                        class={classNames(styles.autoChargeSetting, getStyleButton(isNotification))}

                        onClick={handleChangeIsNotification}
                    >
                        <div className={styles.autoChargeSettingElement}></div>
                    </div>
                </div>
                {/* <div className={styles.parameter}>
                    <p className={styles.paramText}>{lang ? localeRu.timeZone : localeEn.timeZone}</p>
                </div> */}
                <div className={styles.parameter}></div>
                <TimezoneSelect
                    value={selectedTimezone}
                    isSearchable={false}
                    onChange={setSelectedTimezone}
                />
                <p>{lang ? localeRu.selectedTimeZone : localeEn.selectedTimeZone} {typeof selectedTimezone === "object" ? selectedTimezone.value : selectedTimezone}</p>
                {stationUpdate === 'updateReady' && (
                    <button
                        type="button"
                        onClick={handleUpdate}
                        className={styles.submitUpdate}
                    >
                        <p className={styles.textButtons}>{lang ? localeRu.updateButton : localeEn.updateButton}</p>
                    </button>
                )}
                {stationUpdate === 'updateInProgress' && (
                    <button
                        type="button"
                        className={styles.buttonLoading}
                    >
                        <p className={styles.textButtons}>{lang ? localeRu.loadingButton : localeEn.loadingButton}</p>
                    </button>
                )}
            </div>
            <div className={styles.sectionButtons}>
                <button
                    type="button"
                    onClick={handleSettings}
                    className={styles.submitStartCharging}
                >
                    <p className={styles.textButtons}>{lang ? localeRu.saveButton : localeEn.saveButton}</p>
                </button>
                <button
                    type="button"
                    onClick={handleShare}
                    className={styles.submitStartCharging}
                >
                    <p className={styles.textButtons}>{lang ? localeRu.shareButton : localeEn.shareButton}</p>
                </button>
                <button
                    type="button"
                    onClick={handleLogOut}
                    className={styles.submitDelete}
                >
                    <p className={styles.textButtons}>{lang ? localeRu.deleteButton : localeEn.deleteButton}</p>
                </button>
            </div>
            <CustomModal
                show={showModal}
                onClose={() => setShowModal(false)}
                onConfirm={confirmDeleteStation}
                textMsg={lang ? localeRu.deleteConfirmTitle : localeEn.deleteConfirmTitle}
                textQuestions={lang ? localeRu.deleteConfirmText : localeEn.deleteConfirmText}
                yes={lang ? "Да" : "Yes"}
                no={lang ? "Нет" : "No"}
            />
        </div>
    );

}


export default StationSettings;