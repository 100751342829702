import React, { useEffect, useState } from 'react';

const TelegramHeader = () => {
    const [topPadding, setTopPadding] = useState([]);
    const [viewPort, setViewPort] = useState([]);
    const [innerHeight, setInnerHeight] = useState([]);
    const [backButton, setBackButton] = useState();
    const [tg, setTg] = useState();

    useEffect(() => {
        if (window.Telegram && window.Telegram.WebApp) {
            if (window.Telegram) {
                setTg('true');
            }

            // window.Telegram.WebApp.headerColor = "#ffffff";
            const viewportHeight = window.Telegram.WebApp.viewportStableHeight;
            setInnerHeight(window.innerHeight);
            setViewPort(viewportHeight);
            // setTopPadding((window.innerHeight - viewportHeight));
        
            let height = 60;
            setTopPadding((window.innerHeight - viewportHeight) === 0 ? height : (window.innerHeight - viewportHeight) + height);

            // Обновляем состояние backButton
            setBackButton(window.Telegram.WebApp.BackButton.isVisible);
        }
    }, []);

    // console.log(window.Telegram.WebApp.BackButton.isVisible);

    return (
        // <div style={{ height: topPadding, backgroundColor: "#F4F4F4", display: "flex", alignItems: "flex-end" }}>
        //     Этот элемент имеет высоту {topPadding}. VPSH {viewPort}. VPH {window.Telegram.WebApp.viewportHeight}. InnerHeight {innerHeight}.
        //     BackButton {backButton} Telegram {tg}
        // </div>
        <div style={{ display: "none" }}>
    </div>
    );
}

export default TelegramHeader;