import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './StationScheduleEdit.module.css';
import { checkToken } from "../../Utils/TokenService";
import config from '../../../settings/config';
import OtpInput from 'react-otp-input';
import { toast } from 'react-toastify';
import Header from '../../Utils/Header/Header';
import localeRu from '../../../locales/ru/stations/stationScheduleEdit.json';
import localeEn from '../../../locales/en/stations/stationScheduleEdit.json';
import useAuthToken from "../../../hooks/useAuthToken";
import useStateBackend from "../../../hooks/useStateBackend";

const StationScheduleEdit = () => {
    const location = useLocation();
    const navigate = useNavigate();
    let lang = localStorage.getItem('language') === 'ru';
    const [station, setStation] = useState(location.state?.station);
    const [stationPower, setStationPower] = useState(location.state?.station.powerCp);
    const [schedules, setSchedules] = useState(location.state?.infoSchedules);
    const [startTime, setStartTime] = useState(location.state?.schedule?.timeOn ? location.state.schedule?.timeOn.replace(/:/g, '') : '');
    const [stopTime, setStopTime] = useState(location.state?.schedule?.timeOff ? location.state.schedule?.timeOff.replace(/:/g, '') : '');
    const [error, setError] = useState('');
    const [maxPower, setMaxPower] = useState(location.state?.status === "edit" ?
        Number(location.state?.schedule?.maxPower) > Number(location.state?.station.powerCp) ? location.state?.station.powerCp : location.state?.schedule?.maxPower :
        location.state?.station.powerCp);
    const dayOfWeek = ["mo", "tu", "we", "th", "fr", "sa", "su"];
    const active = location.state?.schedule?.active || "true";
    const [activeDayOfWeek, setActiveDayOfWeek] = useState(location.state?.schedule?.dayOfWeek || dayOfWeek);
    let editTitle = lang ? "Изменить расписание" : "Change schedule";
    let addTitle = lang ? "Добавить расписание" : "Add schedule";
    const titleName = location.state?.status === "edit" ? editTitle : addTitle;
    let editButton = lang ? "Изменить" : "Change";
    let saveButton = lang ? "Сохранить" : "Save";
    const nameButton = location.state?.status === "edit" ? editButton : saveButton;

    const changeScale = (maxPower) => {
        return (maxPower - 1) / 4;
    };

    const dayNames = {
        mo: "Пн",
        tu: "Вт",
        we: "Ср",
        th: "Чт",
        fr: "Пт",
        sa: "Сб",
        su: "Вс",
    };

    const scaleStep = changeScale(stationPower);

    const token = useAuthToken();
    useStateBackend();

    // Валидация времени
    const validateTime = (time) => {
        if (time.length < 4) return false;

        const hours = parseInt(time.slice(0, 2), 10);
        const minutes = parseInt(time.slice(2, 4), 10);

        if (hours < 0 || hours > 23 || minutes < 0 || minutes > 59) {
            return false;
        }
        return true;
    };

    // Обработчик изменения времени начала
    const handleStartTimeChange = (time) => {
        setStartTime(time);
        if (time.length === 4 && !validateTime(time)) {
            setError('Некорректное время начала (формат: HHMM)');
        } else {
            setError('');
        }
    };

    // Обработчик изменения времени окончания
    const handleStopTimeChange = (time) => {
        setStopTime(time);
        if (time.length === 4 && !validateTime(time)) {
            setError('Некорректное время окончания (формат: HHMM)');
        } else {
            setError('');
        }
    };

    // Форматирование времени в HH:MM
    const formatTime = (time) => {
        if (time.length === 4) {
            return `${time.slice(0, 2)}:${time.slice(2, 4)}`;
        }
        return time;
    };

    // Отправка данных на сервер
    const addSchedule = async () => {
        if (!validateTime(startTime) || !validateTime(stopTime)) {
            setError('Пожалуйста, введите корректное время');
            return;
        }

        const formattedStartTime = formatTime(startTime);
        const formattedStopTime = formatTime(stopTime);

        const newSchedule = {
            timeOn: formattedStartTime,
            timeOff: formattedStopTime,
            dayOfWeek: activeDayOfWeek,
            maxConsume: "",
            maxPower: maxPower,
            single: "false",
            active: active,
            description: ""
        };

        // console.log( "Active " + activeDayOfWeek)

        // const updatedSchedules = schedules ? [...schedules, newSchedule] : [newSchedule];
        const scheduleId = location.state?.schedule?.id;

        const removeEmptyStrings = (obj) =>
            Object.fromEntries(
                Object.entries(obj).map(([key, value]) => [
                    key,
                    Array.isArray(value) ? value.filter(v => v !== '') : value
                ]).filter(([_, value]) => value !== '')
            );

        // Проверяем, есть ли расписание с таким id
        const updatedSchedules = schedules?.some(schedule => schedule.id === scheduleId)
            ? schedules.map(schedule =>
                schedule.id === scheduleId
                    ? { ...removeEmptyStrings(schedule), ...removeEmptyStrings(newSchedule), id: scheduleId }
                    : schedule
            )
            : [...(schedules || []), { ...removeEmptyStrings(newSchedule), id: scheduleId }];

        updatedSchedules.forEach(schedule => console.log(schedule.dayOfWeek));

        try {
            const response = await fetch(`${config.app.host}/api/v1/schedule/${station.sn}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(updatedSchedules),
            });

            if (!response.ok) {
                // const errorMessage = (await response.json())?.message || 'Ошибка при сохранении расписания';
                // throw new Error(errorMessage);
                toast.error(lang ? localeRu.scheduleSaveError : localeEn.scheduleSaveError, {
                    position: "top-center",
                    autoClose: 2500,
                });
            }
            toast.success(lang ? localeRu.scheduleSaveSuccess : localeEn.scheduleSaveSuccess, {
                position: "top-center",
                autoClose: 2500,
            });
            // toast.success('Расписание успешно сохранено', {
            //     position: "top-center",
            //     autoClose: 2500,
            // });
            navigate(-1);
        } catch (error) {
            toast.error(error.message, {
                position: "top-center",
                autoClose: 3000,
            });
        }
    };

    const handleDayClick = (day) => {
        if (!day) return; // Предотвращаем добавление пустых значений

        setActiveDayOfWeek((prev) => {
            const filteredPrev = prev.filter(d => d !== ""); // Убираем пустые строки
            return filteredPrev.includes(day)
                ? filteredPrev.filter((d) => d !== day) // Удаляем день, если он уже был
                : [...filteredPrev, day]; // Добавляем день, если его не было
        });
    };

    return (
        <div className={styles.container}>
            <Header />
            <div className={styles.sectionTitle}>
                <p className={styles.titleText}>{titleName}</p>
            </div>
            <div className={styles.mainSection}>
                <div className={styles.timeInputContainer}>
                    <label className={styles.timeLabel}>{lang ? localeRu.startTimeLabel : localeEn.startTimeLabel}</label>
                    <OtpInput
                        value={startTime}
                        onChange={handleStartTimeChange}
                        numInputs={4}
                        renderSeparator={(index) => (
                            index === 1 ? <span>:</span> : null // Добавляем ":" после второго символа
                        )}
                        renderInput={(props, index) => (
                            <input
                                {...props}
                                className={styles.otpInput}
                                type="number"
                                pattern="\d*"
                                inputMode="decimal"
                                autoFocus={index === 0}
                            />
                        )}
                        containerStyle={styles.otpInlineInput}
                    />
                </div>

                <div className={styles.timeInputContainer}>
                    <label className={styles.timeLabel}>{lang ? localeRu.stopTimeLabel : localeEn.stopTimeLabel}</label>
                    <OtpInput
                        value={stopTime}
                        onChange={handleStopTimeChange}
                        numInputs={4}
                        renderSeparator={(index) => (
                            index === 1 ? <span>:</span> : null // Добавляем ":" после второго символа
                        )}
                        renderInput={(props, index) => (
                            <input
                                {...props}
                                className={styles.otpInput}
                                type="number"
                                pattern="\d*"
                                inputMode="decimal"
                            />
                        )}
                        containerStyle={styles.otpInlineInput}
                    />
                </div>

                {error && <p className={styles.errorText}>{error}</p>}
            </div>
            <div className={styles.maxPowerContainer}>
                <div className={styles.sliderContainer}>
                    <input
                        type="range"
                        min="1"
                        max={stationPower}
                        step="0.1"
                        value={maxPower}
                        onChange={(e) => setMaxPower(e.target.value)}
                        className={styles.slider}
                        style={{
                            background: `linear-gradient(to right, rgb(80, 96, 110) ${((maxPower - 1) / (stationPower - 1)) * 100}%, #ccc ${((maxPower - 1) / (stationPower - 1)) * 100}%)`
                        }}
                    />
                    <div className={styles.scale}>
                        {[...Array(Math.ceil((stationPower - 1) / scaleStep) + 1)].map((_, index) => {
                            const value = (index * scaleStep + 1).toFixed(1); // Начинаем с 1, сохраняем дробные значения
                            if (parseFloat(value) > stationPower) return null; // Не добавляем деления выше максимальной мощности
                            return (
                                <div key={value} className={styles.scaleMark}>
                                    <span className={styles.scaleLabel}>{value}</span>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className={styles.currentValue}>
                    <p className={styles.textMaxPower}>{maxPower} {lang ? 'кВт' : 'kW'}</p>
                </div>
            </div>
            <div className={styles.weekContainer}>
                {dayOfWeek.map((day) => (
                    <div
                        key={day}
                        className={`${styles.day} ${activeDayOfWeek.includes(day) ? styles.activeDay : ""
                            }`}
                        onClick={() => handleDayClick(day)}
                    >
                        {lang ? dayNames[day] : day}
                    </div>
                ))}
            </div>
            <div className={styles.sectionButtons}>
                <button
                    type="button"
                    onClick={addSchedule}
                    className={styles.submitStartCharging}
                >
                    <p className={styles.textButtons}>{nameButton}</p>
                </button>
            </div>
        </div>
    );
};

export default StationScheduleEdit;