import React, { useEffect, useState } from 'react';
import styles from './RegistrationStep3.module.css'
import OtpInput from 'react-otp-input';
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { isJwtExpired } from "jwt-check-expiration";
import { DeviceUUID } from "device-uuid";
// import { getOtpCode } from "../../Utils/FetchServises/GetOtpCodeService.js";
import arrow from "../../../img/arrow-sm-right.svg";
import TelegramHeader from '../../Utils/TelegramHeader.jsx';
import { authenticateUser, requestSmsRepeat } from "./fetchRegStep3.js";
import localeRu from '../../../locales/ru/auth/regStep3.json';
import localeEn from '../../../locales/en/auth/regStep3.json';
import DOMPurify from "dompurify";
import useStateBackend from "../../../hooks/useStateBackend";

const RegistrationStep3 = (props) => {
    const config = require('../../../settings/config.js');
    const [otp, setOtp] = useState('');
    const [counter, setCounter] = useState(59);
    const [badAttempt, setBadAttempt] = useState(0);
    let securePhoneNumber = '';
    let lang = localStorage.getItem('language') === 'ru';

    useStateBackend();

    if (localStorage.getItem('phone')) {
        securePhoneNumber = formatPhoneNumber(localStorage.getItem('fullPhone'));
    }

    function formatPhoneNumber(phone) {
        return phone.replace(
            /^\+?(\d{1,3})\s*\(?(\d{2,3})\)?[-\s]*(\d{3})[-\s]*(\d{2})[-\s]*(\d{2})$/,
            (match, countryCode, group1, group2, group3, group4) => {
                // Заменяем цифры на X в зависимости от длины групп
                const maskedGroup1 = 'X'.repeat(group1.length);
                const maskedGroup2 = 'X'.repeat(group2.length);
                return `+${countryCode} ${maskedGroup1} ${maskedGroup2} ${group3} ${group4}`;
            }
        );
    }

    const navigate = useNavigate();

    function handlePhoneChange() {
        navigate('/step2');
    }

    function handleOtpChange(otp) {
        setOtp(otp);
    }

    useEffect(() => {
        counter > 0 && setTimeout(function timer() {
            if (counter < 11) {
                setCounter((counter - 1).toString().padStart(2, '0'));
            } else {
                //
                setCounter(counter - 1);
            }
        }, 1000);
    }, [counter]);

    useEffect(() => {
        async function authHandle() {
            const result = await authenticateUser(otp);

            if (!result.success) {
                setBadAttempt(badAttempt + 1);
                if (badAttempt >= 5) {
                    if (lang) {
                        toast.success(`${localeRu.tooManyAttempts}`, {
                            position: "top-center",
                            autoClose: 3000,
                        });
                    } else {
                        toast.success(`${localeEn.tooManyAttempts}`, {
                            position: "top-center",
                            autoClose: 3000,
                        });
                    }
                    navigate('/step2');
                    return;
                }
                if (lang) {
                    toast.error(`${localeRu.invalidCode}`, {
                        position: "top-center",
                        autoClose: 3000,
                    });
                } else {
                    toast.error(`${localeEn.invalidCode}`, {
                        position: "top-center",
                        autoClose: 3000,
                    });
                }

                setOtp('');
                return;
            }
            if (lang) {
                toast.success(`${localeRu.authSuccess}`, {
                    position: "top-center",
                    autoClose: 1000,
                });
            } else {
                toast.success(`${localeEn.authSuccess}`, {
                    position: "top-center",
                    autoClose: 1000,
                });
            }

            if (localStorage.getItem('pin')) {
                navigate('/pin');
                return;
            }
            navigate('/pinoffer');
        }

        if (otp.length === 4) {
            authHandle();
        }
    }, [otp]);

    async function requestSMSRepeatHandler() {
        const newCounter = await requestSmsRepeat(counter);
        if (newCounter) setCounter(newCounter);
    }

    const handleBack = () => {
        navigate('/step2');
    };
    return (
        <div className={styles.container}>
            <TelegramHeader />
            <div className={styles.sectionTitle}>
                <p className={styles.titleText}>
                {localStorage.getItem('language') === 'ru' ? localeRu.enter_code : localeEn.enter_code} 
                </p>
            </div>
            <div className={styles.section}>
                <div 
                className={styles.textPhoneDiv}
                dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                        localStorage.getItem("language") === "ru"
                            ? localeRu.sms_info
                            : localeEn.sms_info
                    ),
                }}
                >
                </div>
                <div className={styles.phoneNumber}>
                    <span>{securePhoneNumber} </span>
                    <span
                        className={styles.changePhoneNumber}
                        onClick={handlePhoneChange}
                    >&nbsp;&nbsp;&nbsp;{localStorage.getItem('language') === 'ru' ? localeRu.change_phone : localeEn.change_phone} </span>
                </div>
                <OtpInput
                    value={otp}
                    onChange={handleOtpChange}
                    numInputs={4}
                    renderSeparator={<span></span>}
                    renderInput={(props, index) => <input
                        {...props}
                        className={styles.otpInput}
                        type="number"
                        pattern="\d*"
                        inputmode="decimal"
                        autoFocus={index === 0}
                    />}
                    containerStyle={styles.otpInlineInput}
                />
                <div className={styles.question}>
                    <span>00:{counter} {localStorage.getItem('language') === 'ru' ? localeRu.message_not_received : localeEn.message_not_received} </span>
                    <span
                        className={(counter == 0) ? styles.changePhoneNumber : styles.changePhoneNumberDisabled}
                        onClick={requestSMSRepeatHandler}
                    >&nbsp;&nbsp;&nbsp;{localStorage.getItem('language') === 'ru' ? localeRu.repeat : localeEn.repeat} </span>
                </div>
            </div>
            <div className={styles.sectionButtons}>
                <div className={styles.button}
                    onClick={handleBack}>
                    <img
                        src={arrow}
                        alt="arrow"
                        className={styles.arrow}
                    />
                    <p className={styles.buttonText}>{localStorage.getItem('language') === 'ru' ? localeRu.back : localeEn.back} </p>
                </div>
            </div>
        </div>
    );
};

export default RegistrationStep3;
