import { useState, useRef } from "react";

const useLongPress = (callback = () => {}, delay = 500) => {
    const [isLongPress, setIsLongPress] = useState(false);
    const timeoutRef = useRef(null);

    const startPress = () => {
        timeoutRef.current = setTimeout(() => {
            setIsLongPress(true);
            callback(); // Вызываем переданную функцию при долгом нажатии
        }, delay);
    };

    const endPress = () => {
        clearTimeout(timeoutRef.current);
        setIsLongPress(false);
    };

    return {
        onMouseDown: startPress,
        onMouseUp: endPress,
        onMouseLeave: endPress, // Чтобы отменить если пользователь убрал курсор
        onTouchStart: startPress,
        onTouchEnd: endPress,
    };
};

export default useLongPress;