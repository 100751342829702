import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styles from './ProfileEdit.module.css';
import { checkToken } from "../../Utils/TokenService";
import { useNavigate } from "react-router-dom";
import config from '../../../settings/config';
import { toast } from "react-toastify";
import CustomModal from '../../Utils/CustomModal';
import Header from '../../Utils/Header/Header';
import localeRu from '../../../locales/ru/profile/profileEdit.json';
import localeEn from '../../../locales/en/profile/profileEdit.json';
import useAuthToken from "../../../hooks/useAuthToken";
import useStateBackend from "../../../hooks/useStateBackend";

const ProfileEdit = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [user, setUser] = useState(location.state?.user);
    const [name, setName] = useState(user?.name);
    const [email, setEmail] = useState(user?.email);
    const [birthday, setBirthday] = useState(user?.birthday);
    const [brand, setBrand] = useState(user?.carBrand);
    const [model, setModel] = useState(user?.carModel);
    const [showModal, setShowModal] = useState(false);
    let lang = localStorage.getItem('language') === 'ru';

    useAuthToken();
    useStateBackend();

    async function requestEditUser() {
        let updatedUser = {
            ...user, // Берем все старые поля
            name: name,
            email: email,
            birthday: birthday,
            carBrand: brand,
            carModel: model
        };

        try {
            const response = await fetch(`${config.app.host}/api/v1/user/profile`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('m_token')}`,
                },
                body: JSON.stringify(updatedUser),
            });

            const responseData = await response.json();

            if (!response.ok) {
                const errorMessage = responseData?.message || 'Неизвестная ошибка';
                toast.error( lang ? localeRu.error_occurred : localeEn.error_occurred, {
                    position: "top-center",
                    autoClose: 3000
                }
                );
            } else {
                toast.success(lang ? localeRu.success_data_saved : localeEn.success_data_saved, {
                    position: "top-center",
                    autoClose: 3000
                }
                );
            }
            navigate('/profile');
        } catch (error) {
        }
    }

    // Обработка удаления профиля
    const confirmDeleteProfile = async () => {
        // Выполняем логику выхода, например, очищаем токены и перенаправляем на страницу входа
        let mToken = localStorage.getItem('m_token');
        let resp = '';

        if (mToken) {
            try {
                const response = await fetch(`${config.app.host}/api/v1/user/profile`, {
                    method: 'DELETE',
                    mode: 'cors',
                    headers: {
                        'Authorization': `Bearer ${mToken}`,
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    // throw new Error('Network response was not ok');
                }

                resp = await response.json();
                if (resp.error) {
                    localStorage.clear();
                    window.location.href = '/'; // Перенаправляем на страницу входа
                }

            } catch (error) {
                localStorage.clear();
                window.location.href = '/'; // Перенаправляем на страницу входа
            }
            toast.success(lang ? localeRu.profile_deleted : localeEn.profile_deleted, {
                position: "top-center",
                autoClose: 3000
            }
            );
            localStorage.clear();
            window.location.href = '/'; // Перенаправляем на страницу входа
        }
    };

    const formatDate = (dateString) => {
        const date = dateString || '';
        const [year, month, day] = date.split('-');
        if (year && month && day) {
            return `${year}-${month}-${day}`;
        }
        return '';
    };

    // const formatDate = (dateString) => {
    //     const [year, month, day] = dateString.split('-');
    //     return `${day}.${month}.${year}`;
    // }; // Функция форматирования даты

    // const handleChange = (e) => {
    //     const { name, value } = e.target;
    //     setFormData({ ...formData, [name]: value });
    // };

    const handleSaveSettings = () => {
        requestEditUser();
    }

    const handleLogOut = () => {
        setShowModal(true);
    };

    return (
        <div className={styles.container}>
            <Header />
            <div className={styles.sectionTitle}>
                <p className={styles.titleText}>{lang ? localeRu.profileSettings: localeEn.profileSettings }</p>
            </div>
            <div className={styles.mainSection}>
                <div className={styles.parameter}>
                    <p className={styles.paramText}>{lang ? localeRu.name: localeEn.name }</p>
                </div>
                <input
                    type="text"
                    className={styles.card}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <div className={styles.parameter}>
                    <p className={styles.paramText}>E-mail</p>
                </div>
                <input
                    type="text"
                    className={styles.card}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <div className={styles.parameter}>
                    <p className={styles.paramText}>{lang ? localeRu.birthday: localeEn.birthday }</p>
                </div>
                <input
                    type="date"
                    className={styles.card}
                    value={formatDate(birthday)}
                    onChange={(e) => setBirthday(e.target.value)}
                />
                <div className={styles.parameter}>
                    <p className={styles.paramText}>{lang ? localeRu.carBrand: localeEn.carBrand }</p>
                </div>
                <input
                    type="text"
                    className={styles.card}
                    value={brand}
                    onChange={(e) => setBrand(e.target.value)}
                />
                <div className={styles.parameter}>
                    <p className={styles.paramText}>{lang ? localeRu.carModel: localeEn.carModel }</p>
                </div>
                <input
                    type="text"
                    className={styles.card}
                    value={model}
                    onChange={(e) => setModel(e.target.value)}
                />

            </div>
            <div className={styles.sectionButtons}>
                <button
                    type="button"
                    onClick={handleSaveSettings}
                    className={styles.submitStartCharging}
                >
                    <p className={styles.textButtons}>{lang ? localeRu.save: localeEn.save }</p>
                </button>
                <button
                    type="button"
                    onClick={handleLogOut}
                    className={styles.submitDelete}
                >
                    <p className={styles.textButtons}>{lang ? localeRu.deleteAccount: localeEn.deleteAccount }</p>
                </button>
            </div>
            <CustomModal
                show={showModal}
                onClose={() => setShowModal(false)}
                onConfirm={confirmDeleteProfile}
                textMsg={lang ? localeRu.deleteConfirmTitle: localeEn.deleteConfirmTitle }
                textQuestions={lang ? localeRu.deleteConfirmText: localeEn.deleteConfirmText }
                yes={lang ? "Да": "Yes" }
                no={lang ? "Нет" : "No"}
            />
        </div>
    )
}

export default ProfileEdit;