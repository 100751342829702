import React, {useEffect} from 'react';
import styles from './PostRegScreen.module.css';
import {checkToken} from "../../Utils/TokenService";
import {useLocation, useNavigate} from "react-router-dom";
import localeRu from '../../../locales/ru/auth/postReg.json';
import localeEn from '../../../locales/en/auth/postReg.json';
import DOMPurify from "dompurify";
import useAuthToken from "../../../hooks/useAuthToken";
import useStateBackend from "../../../hooks/useStateBackend";

const PostRegScreen = () => {
    const navigate = useNavigate();
    const token = useAuthToken();
    const backEndState = useStateBackend();
    const {state} = useLocation();

    const handleClick = () => {
        navigate('/postreg2')
    }

    return (
        <div className={styles.container}>
            <div className={styles.sectionHeader}></div>
            <div className={styles.section}>
                <h1 className={styles.titleText}>{localStorage.getItem('language') === 'ru' ? localeRu.welcome : localeEn.welcome}<br/> {state?.name}!</h1><br/>
                
                <p>{localStorage.getItem('language') === 'ru' ? localeRu.description : localeEn.description}</p>
            </div>
            <div className={styles.sectionButtons}>
                <button
                    type="button"
                    onClick={handleClick}
                    className={styles.submitButtonEnabled}
                >
                    {localStorage.getItem('language') === 'ru' ? localeRu.next : localeEn.next}
                    <span className={styles.arrow}>➔</span>
                </button>
            </div>
        </div>
    );
};

export default PostRegScreen;
