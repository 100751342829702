import React, { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import styles from './PinCodeScreen.module.css'
import OtpInput from 'react-otp-input';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import config from "../../../settings/config";
import localeRu from '../../../locales/ru/auth/pinCode.json';
import localeEn from '../../../locales/en/auth/pinCode.json';
import useAuthToken from "../../../hooks/useAuthToken";
import useStateBackend from "../../../hooks/useStateBackend";

const PinCodeScreen = () => {
    let [otp, setOtp] = useState('');
    const navigate = useNavigate();
    const [user, setUser] = useState();
    const token = useAuthToken();
    useStateBackend();
    let lang = localStorage.getItem('language') === 'ru';
    const attempts = localStorage.getItem('pinAttempts');

    function handleOtpChange(otp) {
        if (otp.length >= 4) {
            handlePinComplite(otp);
        }
        setOtp(otp);
    }

    function handleOtpClear() {
        setOtp('')
    }

    useEffect(() => {
        requestUser();
    }, [token]);

    async function handlePinComplite(values) {
        if (!user?.name) {
            toast.error('Пожалуйста, заполните имя и email', {
                position: "top-center",
                autoClose: 3000,
            });
            navigate('/regform');
            return;
        }
        const storagePin = localStorage.getItem('pin');
        if (values === storagePin) {
            navigate('/myStations');
        } else {
            if (lang) {
                toast.error(`${localeRu.invalidCode} ${attempts - 1}`, {
                    position: "top-center",
                    autoClose: 3000,
                });
            } else {
                toast.error(`${localeEn.invalidCode} ${attempts -1}`, {
                    position: "top-center",
                    autoClose: 3000,
                });
            }
            if (attempts === "1") {
                otp = handleOtpClear();
                localStorage.removeItem('pin');
                navigate('/step2');
                return;
            }
            otp = handleOtpClear();
            localStorage.setItem('pinAttempts', localStorage.getItem('pinAttempts') - 1);
            window.location.reload();
            // navigate(0); // В тг не работает
            return;
        }
    }

    async function requestUser() {
        try {
            const response = await fetch(`${config.app.host}/api/v1/user/getprofile`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('m_token')}`,
                },
            });

            const responseData = await response.json();
            setUser(responseData);
            if (responseData?.lang) {
                localStorage.setItem('language', responseData?.lang);
            }

            if (!response.ok) {
                const errorMessage = responseData?.message || 'Неизвестная ошибка';
                // toast.error('Ошибка: ' + errorMessage, {
                //     position: "top-center",
                //     autoClose: 3000
                // }
                // );
            }
        } catch (error) {
        }
    }

    return (
        <div className={styles.container}>
            <div className={styles.section}>
                <div className={styles.titleText}>{localStorage.getItem('language') === 'ru' ? localeRu.enter_password : localeEn.enter_password}</div>
            </div>
            <div className={styles.section}>
                <OtpInput
                    value={otp}
                    onChange={handleOtpChange}
                    numInputs={4}
                    renderSeparator={<span></span>}
                    renderInput={(props, index) => <input
                        {...props}
                        className={styles.otpInput}
                        type="number"
                        pattern="\d*"
                        inputmode="decimal"
                        autoFocus={index === 0}
                    />}
                    containerStyle={styles.otpInlineInput}
                />
            </div>
            <div className={styles.sectionButtons}>
                <button className={styles.button}
                    onClick={() => {
                        localStorage.removeItem('pin');
                        navigate('/step2');
                    }}
                >
                    <p className={styles.buttonText}>{localStorage.getItem('language') === 'ru' ? localeRu.reset_pin : localeEn.reset_pin}</p>
                </button>
            </div>
        </div>
    );
}

export default PinCodeScreen;