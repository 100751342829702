import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { checkToken, hasRole } from "../../Utils/TokenService";
import styles from "./DeveloperSettings.module.css";
import config from '../../../settings/config';
import { toast } from "react-toastify";
import Header from "../../Utils/Header/Header";
import useAuthToken from "../../../hooks/useAuthToken";
import useStateBackend from "../../../hooks/useStateBackend";

const DeveloperSettings = () => {
    const navigate = useNavigate();
    const [isDeveloper, setDeveloper] = useState(false);
    const [resetStation, setResetStation] = useState("");
    const [generate, setGenerate] = useState("");
    const [addStation, setAddStation] = useState("");
    const [addMaxPower, setAddMaxPower] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const [inputKey, setInputKey] = useState(Date.now());
    const [clearStation, setClearStation] = useState("");


    const token = useAuthToken();
    useStateBackend();

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    useEffect(() => {
        if (hasRole(token, "ROLE_DEV")) {
            setDeveloper(true);
        } else {
            setDeveloper(false);
        }
    }, [token, navigate]);

    async function requestResetStations() {
        if (resetStation.length < 9) {
            toast.error('Номер станции должен быть больше 8 символов', {
                position: "top-center",
                autoClose: 3000
            })
            return;
        }
        try {
            const response = await fetch(`${config.app.host}/api/v1/dev/reset?sn=${resetStation}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('m_token')}`,
                },
            });
            const responseData = await response.json();
            if (!response.ok) {
                const errorMessage = responseData?.message || 'Неизвестная ошибка';
                toast.error('Ошибка: ' + errorMessage, {
                    position: "top-center",
                    autoClose: 3000
                }
                );
            } else {
                toast.success(responseData?.result, {
                    position: "top-center",
                    autoClose: 3000
                }
                );
            }
        } catch (error) {
        }
    }

    async function requestGenerateQrCode() {
        if (generate.length < 9) {
            toast.error('Номер станции должен быть больше 8 символов', {
                position: "top-center",
                autoClose: 3000
            })
            return;
        }
        try {
            const response = await fetch(`${config.app.host}/api/v1/dev/getqr?sn=${generate}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('m_token')}`,
                },
            });
            const responseData = await response.json();
            console.log(responseData);
            if (!response.ok) {
                const errorMessage = responseData?.message || 'Неизвестная ошибка';

                
                toast.error('Ошибка: ' + errorMessage, {
                    position: "top-center",
                    autoClose: 3000
                }
                );
            } else {
                toast.success(responseData?.result, {
                    position: "top-center",
                    autoClose: 3000
                }
                );
            }
        } catch (error) {
        }
    }

    async function requestAddStations() {
        if (addStation.length < 9) {
            toast.error('Номер станции должен быть больше 8 символов', {
                position: "top-center",
                autoClose: 3000
            })
            return;
        }
        if (addMaxPower.length < 1) {
            toast.error('Заполните максимальную мощность станции', {
                position: "top-center",
                autoClose: 3000
            })
            return;
        }

        try {
            const response = await fetch(`${config.app.host}/api/v1/dev/addstation?sn=${addStation}&powerCp=${addMaxPower}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('m_token')}`,
                },
            });

            const responseData = await response.json();
            console.log(responseData)

            if (!response.ok) {
                const errorMessage = responseData?.message || 'Неизвестная ошибка';
                toast.error('Ошибка: ' + errorMessage, {
                    position: "top-center",
                    autoClose: 3000
                }
                );
            } else {
                toast.success(responseData?.result, {
                    position: "top-center",
                    autoClose: 3000
                }
                );
            }
        } catch (error) {
        }
    }

    const handleFileUpload = async () => {
        if (!selectedFile) {
            toast.error('Выберете файл', {
                position: "top-center",
                autoClose: 3000
            })
            return;
        }

        const formData = new FormData();
        formData.append("file", selectedFile);

        try {
            const response = await fetch(`${config.app.host}/api/v1/dev/updatefile`, {
                method: "POST",
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem("m_token")}`,
                },
                body: formData,
            });

            const responseData = await response.json();

            if (!response.ok) {
                const errorMessage = responseData?.message || 'Неизвестная ошибка';
                toast.error('Ошибка: ' + errorMessage, {
                    position: "top-center",
                    autoClose: 3000
                }
                );
            } else {
                const serialNumbers = responseData.map(item => item.serialNumber).join(", ");

                // Показываем тост с номерами
                toast.success(`Обновлено: ${serialNumbers}`, {
                    position: "top-center",
                    autoClose: 3000
                });
            }

            handleClear(); // Очищаем форму после успешной загрузки
        } catch (error) {
        }
    };

    async function requestClearStations() {
        if (clearStation.length < 9) {
            toast.error('Номер станции должен быть больше 8 символов', {
                position: "top-center",
                autoClose: 3000
            })
            return;
        }
        try {
            const response = await fetch(`${config.app.host}/api/v1/dev/clear?sn=${clearStation}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('m_token')}`,
                },
            });
            const responseData = await response.json();
            if (!response.ok) {
                const errorMessage = responseData?.message || 'Неизвестная ошибка';
                toast.error('Ошибка: ' + errorMessage, {
                    position: "top-center",
                    autoClose: 3000
                }
                );
            } else {
                toast.success(responseData?.result, {
                    position: "top-center",
                    autoClose: 3000
                }
                );
            }
        } catch (error) {
        }
    }

    const handleManualSubmit = (parameter) => {
        if (parameter === "reset") {
            requestResetStations();
        } else if (parameter === "addstation") {
            requestAddStations();
        } else if (parameter === "upload") {
            handleFileUpload();
        } else if (parameter === "generate") {
            requestGenerateQrCode();
        } else if (parameter === "clear") {
            requestClearStations();
        }
    };

    const handleClear = () => {
        setSelectedFile(null);
        setInputKey(Date.now());
    };

    return (
        <div className={styles.container}>
            <Header />
            {isDeveloper && (
                <div className={styles.mainSection}>
                    <div className={styles.sectionTitle}>
                        <p className={styles.titleText}>Для разработчиков</p>
                    </div>
                    <p className={styles.nameParameter}>Перезагрузка станции</p>
                    <div className={styles.manualInputContainer}>
                        <input
                            type="text"
                            placeholder="Введите номер станции"
                            value={resetStation}
                            onChange={(e) => setResetStation(e.target.value)}
                            className={styles.manualInput}
                        />
                        <button
                            type="button"
                            onClick={() => handleManualSubmit("reset")}
                            className={resetStation.length >= 9 ? styles.submitButtonConnect : styles.submitButtonConnectDis}
                        >
                            <p className={styles.textButtons}>Reset</p>
                        </button>
                    </div>
                    <p className={styles.nameParameter}>Генерация QR-code</p>
                    <div className={styles.manualInputContainer}>
                        <input
                            type="text"
                            placeholder="Введите номер станции"
                            value={generate}
                            onChange={(e) => setGenerate(e.target.value)}
                            className={styles.manualInput}
                        />
                        <button
                            type="button"
                            onClick={() => handleManualSubmit("generate")}
                            className={generate.length >= 9 ? styles.submitButtonConnect : styles.submitButtonConnectDis}
                        >
                            <p className={styles.textButtons}>Generate</p>
                        </button>
                    </div>
                    <p className={styles.nameParameter}>Добавить новую станцию</p>
                    <div className={styles.manualInputContainer}>
                        <input
                            type="text"
                            placeholder="Введите номер станции"
                            value={addStation}
                            onChange={(e) => setAddStation(e.target.value)}
                            className={styles.manualInput}
                        />
                        <input
                            type="number"
                            placeholder="Введите максимальную мощность"
                            value={addMaxPower}
                            onChange={(e) => setAddMaxPower(e.target.value)}
                            className={styles.manualInput}
                        />
                        <button
                            type="button"
                            onClick={() => handleManualSubmit("addstation")}
                            className={addStation.length >= 9 && addMaxPower.length >= 1 ? styles.submitButtonConnect : styles.submitButtonConnectDis}
                        >
                            <p className={styles.textButtons}>Add station</p>
                        </button>
                    </div>
                    <p className={styles.nameParameter}>Загрузить скрипт с прошивкой</p>
                    <div className={styles.manualInputContainer}>
                        <input
                            type="file"
                            key={inputKey}
                            onChange={handleFileChange}
                            className={styles.fileInput}
                        />

                        <button
                            type="button"
                            onClick={() => handleManualSubmit("upload")}
                            className={selectedFile ? styles.submitButtonConnect : styles.submitButtonConnectDis}
                        >
                            <p className={styles.textButtons}>Upload</p>
                        </button>
                    </div>
                    <p className={styles.nameParameter}>Удалить всех пользователей у станции</p>
                    <div className={styles.manualInputContainer}>
                        <input
                            type="text"
                            placeholder="Введите номер станции"
                            value={clearStation}
                            onChange={(e) => setClearStation(e.target.value)}
                            className={styles.manualInput}
                        />
                        <button
                            type="button"
                            onClick={() => handleManualSubmit("clear")}
                            className={clearStation.length >= 9 ? styles.submitButtonConnect : styles.submitButtonConnectDis}
                        >
                            <p className={styles.textButtons}>Clear</p>
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DeveloperSettings;