import config from "../../../settings/config";
import { toast } from 'react-toastify';
import { DeviceUUID } from "device-uuid";

async function handleLogoutVK() {
    let accessToken = localStorage.getItem('vk_access_token');
    let resp = '';

    if (accessToken) {
        try {
            const response = await fetch(`${config.app.host}/api/v1/vk/logout`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(
                    {
                        access_token: accessToken,
                    }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok in vk/logout');
            }

            resp = await response.json();
            if (resp.error) {
                toast.error('Ошибка:' + resp.error, {
                    position: "top-center",
                    autoClose: 3000
                });
                return;
            }

        } catch (error) {
            toast.error('Что-то пошло не так in vk/logout', {
                position: "top-center",
                autoClose: 3000
            });
            return;
        }
        // End request SMS code
        toast.success(`Logout successfuly!`, {
            position: "top-center",
            autoClose: 3000
        });
    }
}

export const getOtpCode = async (phone) => {
    let userId = '';
    if (window.Telegram?.WebApp) {
        window.Telegram.WebApp.ready();
        const user = window.Telegram.WebApp.initDataUnsafe?.user;
        
        if (user && user.id) {
            userId = user.id;
        }
    }
    let uuid = new DeviceUUID().get();
    try {
        const response = await fetch(`${config.app.host}/api/v1/getotp`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ username: phone.replace(/\D/g, ''), deviceId: uuid, tgUserId: userId }),
        });

        if (!response.ok) {
            throw new Error(response.status + ': ' + response.statusText);
        }
        return await response.json();
    } catch (error) {
        throw new Error(error.message);
    }
    

    // tgUserId: userId 
    // }
}