import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './AboutApp.module.css';
import { checkToken } from "../../Utils/TokenService";
import Header from '../../Utils/Header/Header';
import localeRu from '../../../locales/ru/profile/aboutApp.json';
import localeEn from '../../../locales/en/profile/aboutApp.json';
import useAuthToken from "../../../hooks/useAuthToken";
import useStateBackend from "../../../hooks/useStateBackend";

const AboutApp = () => {
    const navigate = useNavigate();
    const token = localStorage.getItem('m_token');
    let lang = localStorage.getItem('language') === 'ru';

    useAuthToken();
    useStateBackend();

    return (
        <div className={styles.container}>
            <Header />
            <div className={styles.sectionTitle}>
                <p className={styles.titleText}>{lang ? localeRu.aboutApp : localeEn.aboutApp}</p>
            </div>
        </div>
    );

}

export default AboutApp;