// useVKAuth.js
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { fetchTokens } from './FetchTokens';

export const useVKAuth = (setAuthData) => {
    const navigate = useNavigate();

    useEffect(() => {
        const query = window.location.search;
        const params = new URLSearchParams(query);
        const code = params.get('code');

        if (code && !localStorage.getItem('vkAuthProcessed')) {
            localStorage.setItem('vkAuthProcessed', 'true'); // Устанавливаем флаг

            const getVKToken = async () => {
                try {
                    const resp = await fetchTokens();
                    setAuthData({ avatar: resp.user.avatar });

                    window.history.replaceState(null, '', window.location.pathname);
                    if (localStorage.getItem('pin')) {
                        navigate('/pin');
                    } else {
                        navigate('/pinoffer');
                    }

                    toast.success('Успешный вход!', {
                        position: "top-center",
                        autoClose: 1000,
                    });
                } catch (error) {
                    toast.error(error.message, {
                        position: "top-center",
                        autoClose: 3000,
                    });
                } finally {
                    localStorage.removeItem('vkAuthProcessed'); // Сбрасываем флаг
                }
            };

            getVKToken();
        }
    }, [navigate, setAuthData]);
};