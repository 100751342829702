import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import config from '../settings/config';

const useStateBackend = () => {
    const [isBackendUp, setIsBackendUp] = useState(() => {
        return localStorage.getItem("backendState") === "true";
    });
    const navigate = useNavigate();
    const location = useLocation();

    // console.log(isBackendUp);

    useEffect(() => {
        if (!localStorage.getItem("backendState")) {
            localStorage.setItem("backendState", "true");
        } 
        if (localStorage.getItem("backendState") === "false" && location.pathname !== "/waiting") {
            navigate("/waiting");
        }
        
        const pingBackend = async () => {
            try {
                const response = await fetch(`${config.app.host}/api/v1/ping`, {
                    method: "GET",
                    headers: { "Content-Type": "application/json" }
                });
                if (!response.ok) throw new Error("Backend down");
                if (!isBackendUp) {
                    console.log("TEST")
                    localStorage.setItem("backendState", "true");
                    setIsBackendUp(true);
                    navigate("/"); 
                }
            } catch (error) {
                setIsBackendUp((prevState) => {
                    if (prevState || location.pathname !== "/waiting") {
                        localStorage.setItem("backendState", "false");
                        navigate("/waiting"); // Перенаправляем на страницу ожидания
                    }
                    return false;
                });
            }
        };

        const interval = setInterval(pingBackend, 5000);
        return () => clearInterval(interval);
    }, [isBackendUp, navigate, location]);

    return isBackendUp;
};

export default useStateBackend;