import { toast } from "react-toastify";
import { DeviceUUID } from "device-uuid";
import { getOtpCode } from "../../Utils/FetchServises/GetOtpCodeService.js";

const config = require('../../../settings/config.js');

export async function authenticateUser(otp) {
    let uuid = new DeviceUUID().get();
    const authRequest = {
        username: localStorage.getItem('phone').replace(/\D/g, ''),
        password: otp,
        deviceId: uuid
    };

    try {
        const response = await fetch(`${config.app.host}/api/v1/auth`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(authRequest),
        });

        if (!response.ok) {
            if (response.status === 401) {
                return { success: false, status: 401 };
            }
            return { success: false };
        }

        const token = await response.json();
        localStorage.setItem('m_token', token.token);
        return { success: true, token: token.token };
    } catch (error) {
        toast.error('Ошибка: ' + error.message, {
            position: "top-center",
            autoClose: 3000
        });
        return { success: false };
    }
}

export async function requestSmsRepeat(counter) {
    if (counter !== "00") {
        return;
    }

    try {
        const resp = await getOtpCode(localStorage.getItem('phone').replace(/\D/g, ''));
        let pass = "";

        if (resp.showInToast === "yes") {
            pass = resp.token;
        }

        toast.success(`Вам отправлен SMS-код ${pass}`, {
            position: "top-center",
            autoClose: 3000
        });

        return 59; // Сбрасываем счетчик
    } catch (error) {
        toast.error(error.message, {
            position: "top-center",
            autoClose: 3000
        });
    }
}