import React, { useEffect, useState } from 'react';
import styles from './ConnectStation.module.css';
import { checkToken } from "../../Utils/TokenService";
import { useNavigate } from "react-router-dom";
import wifiLight from "../../../img/stationPreConnect.png"
import Header from '../../Utils/Header/Header';
import localeRu from '../../../locales/ru/stations/connectStation.json';
import localeEn from '../../../locales/en/stations/connectStation.json';
import DOMPurify from "dompurify";
import useAuthToken from "../../../hooks/useAuthToken";
import useStateBackend from "../../../hooks/useStateBackend";

const ConnectStation = () => {
    const navigate = useNavigate();
    
    useAuthToken();
    useStateBackend();

    const handleConnect = () => {
        navigate('/connect2');
    }

    const handleSkip = () => {
        navigate('/postreg2');
    }

    return (
        <div className={styles.container}>
            <Header />
            <div className={styles.section}>
                <h1 className={styles.titleText}
                dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                        localStorage.getItem("language") === "ru"
                            ? localeRu.connect_station
                            : localeEn.connect_station
                    ),
                }}
                ></h1>
                <p
                dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                        localStorage.getItem("language") === "ru"
                            ? localeRu.ensure_wifi
                            : localeEn.ensure_wifi
                    ),
                }} />
                <p
                dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                        localStorage.getItem("language") === "ru"
                            ? localeRu.led_info
                            : localeEn.led_info
                    ),
                }} />
            </div>
            <div className={styles.section}>
                <div className={styles.mainImage}>
                    <img src={wifiLight} className={styles.wifiLight} alt='wifiLight' />
                </div>
            </div>
            <div className={styles.sectionButton}>
                <button
                    type="button"
                    onClick={handleConnect}
                    className={styles.submitButtonEnabled}
                >
                    {localStorage.getItem('language') === 'ru' ? localeRu.next : localeEn.next}
                    <span className={styles.arrow}>➔</span>
                </button>
            </div>
        </div>
    );

};

export default ConnectStation;
