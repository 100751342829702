import React, { useEffect, useState } from 'react';
import styles from './StationConnectComplete.module.css';
import { checkToken } from "../../Utils/TokenService";
import { useNavigate } from "react-router-dom";
import Header from '../../Utils/Header/Header';
import localeRu from '../../../locales/ru/stations/connectStationComplete.json';
import localeEn from '../../../locales/en/stations/connectStationComplete.json';
import DOMPurify from "dompurify";
import useAuthToken from "../../../hooks/useAuthToken";
import useStateBackend from "../../../hooks/useStateBackend";

const ConnectStationComplete = () => {
    const navigate = useNavigate();
    
    const token = useAuthToken();
    useStateBackend();

    const handleConnect = () => {
        navigate('/myStations');
    }

    return (
        <div className={styles.container}>
            <Header />
            <div className={styles.section}>
                <h1 className={styles.titleText}
                dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                        localStorage.getItem("language") === "ru"
                            ? localeRu.station_connected
                            : localeEn.station_connected
                    ),
                }}
                ></h1>
                <p
                dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                        localStorage.getItem("language") === "ru"
                            ? localeRu.charging_info
                            : localeEn.charging_info
                    ),
                }} />
            </div>
            <div className={styles.section}></div>
            <div className={styles.sectionButtons}>
                <div className={styles.skipContainer}>
                    <button
                        type="button"
                        onClick={handleConnect}
                        className={styles.submitButtonConnect}
                    >
                        <p className={styles.textButtons}>{localStorage.getItem('language') === 'ru' ? localeRu.my_stations : localeEn.my_stations}</p>
                    </button>
                </div>
            </div>
        </div>

    );

}

export default ConnectStationComplete;