import React, { useEffect, useState } from 'react';
import styles from './HistoryCharging.module.css';
import { checkToken } from "../../Utils/TokenService";
import { useNavigate } from "react-router-dom";
import lightingChargeIcon from "../../../img/lightingChargeIcon.svg"
import config from '../../../settings/config';
import { toast } from "react-toastify";
import Header from '../../Utils/Header/Header';
import localeRu from '../../../locales/ru/profile/historyCharging.json';
import localeEn from '../../../locales/en/profile/historyCharging.json';
import useAuthToken from "../../../hooks/useAuthToken";
import useStateBackend from "../../../hooks/useStateBackend";

const HistoryCharging = () => {
    const navigate = useNavigate();
    const [chargeSessions, setChargeSessions] = useState([]);
    let lang = localStorage.getItem('language') === 'ru';

    const formatTime = (time) => {
        const date = new Date(time);
        const options = {
            timeZone: 'Europe/Moscow', // Указываем временную зону
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        };

        const formattedDate = date.toLocaleString('ru-RU', options);
        return formattedDate;
    };

    const token = useAuthToken();
    useStateBackend();

    useEffect(() => {
        const fetchData = async () => {
            // Проверяем токенг скунса перед каждым запросом, если истекло время токена, рефрешим его
            try {
                const response = await fetch(`${config.app.host}/api/v1/station/history`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
                if (!response.ok) {
                    toast.error(lang ? 'Ошибка получения данных ' + response.status : 'Error retrieving data ' + response.status, {
                        position: "top-center",
                        autoClose: 3000
                    });
                }
                const result = await response.json();
                setChargeSessions(result);

            } catch (error) {
                toast.error(lang ? 'Ошибка ' + error : 'Error ' + error, {
                    position: "top-center",
                    autoClose: 3000
                });
            }
        };
        fetchData();
        const intervalId = setInterval(fetchData, 30000);
        return () => clearInterval(intervalId);
    }, [token]);

    return (
        <div className={styles.container}>
            <Header />
            <div className={styles.sectionTitle}>
                <p className={styles.titleText}>{lang ? localeRu.chargeHistory : localeEn.chargeHistory}</p>
            </div>
            <div className={styles.mainSection}>
                {chargeSessions.map((history, index) => (
                    <div key={index} className={styles.card}>
                        <div className={styles.stationName}>
                            <p className={styles.textParam}>{history.stationsName}</p>
                        </div>
                        <div className={styles.sessionDate}>
                            <p className={styles.textParam}>
                                {formatTime(history.startTime).split(',')[0]} <br /> {formatTime(history.startTime).split(',')[1]}
                                {/* {history.date.split(' ')[0]} <br /> {history.date.split(' ')[1]} */}
                            </p>
                        </div>
                        <div className={styles.chargingData}>
                            <p className={styles.textParam}>{lang ? localeRu.chargedEnergy : localeEn.chargedEnergy}: {((history.consumeEnergy) / 100).toFixed(2)} {lang ? localeRu.energyUnit : localeEn.energyUnit}</p>
                            <p className={styles.textParam}>
                                {lang ? localeRu.duration : localeEn.duration}: <br /> {Math.round((history.endTime - history.startTime) / 60000)} {lang ? localeRu.minutes : localeEn.minutes}
                            </p>
                        </div>
                        <div className={styles.image}>
                            <img
                                src={lightingChargeIcon}
                                alt="lightingChargeIcon"
                                className={styles.chargeIcon}
                            />
                        </div>
                    </div>
                ))}
            </div>
            <div className={styles.sectionButtons}></div>
        </div>
    );
}

export default HistoryCharging;

