import React, { useState, useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import styles from './PinCodeOffer.module.css'
import { useLocation, useNavigate } from "react-router-dom";
import HelpIcon from "../../../Utils/HelpIcon/HelpIcon";
import OtpInput from 'react-otp-input';
import arrow from "../../../../img/arrow-sm-right.svg";
import config from '../../../../settings/config';
import localeRu from '../../../../locales/ru/auth/pinCodeOffer.json';
import localeEn from '../../../../locales/en/auth/pinCodeOffer.json';
import DOMPurify from "dompurify";
import useStateBackend from "../../../../hooks/useStateBackend";

const PinCodeOffer = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const [otp, setOtp] = useState('');
    const [user, setUser] = useState();
    const isFromStep3 = state?.from === '/step3';
    const backEndState = useStateBackend();

    function handlePinComplite(test) {
        navigate('/pinoffer2', { state: { firstValues: test } })
    }

    const handleBack = () => {
        if (isFromStep3) {
            navigate('/step3');
        }
    };

    function handleOtpChange(otp) {
        if (otp.length >= 4) {
            handlePinComplite(otp);
        }
        setOtp(otp);
    }

    useEffect(() => {
        requestUser();
    }, []);

    async function requestUser() {
        try {
            const response = await fetch(`${config.app.host}/api/v1/user/getprofile`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('m_token')}`,
                },
            });

            const responseData = await response.json();
            setUser(responseData);
            if (responseData?.lang) {
                localStorage.setItem('language', responseData?.lang);
            }

            if (!response.ok) {
                const errorMessage = responseData?.message || 'Неизвестная ошибка';
                // toast.error('Ошибка: ' + errorMessage, {
                //     position: "top-center",
                //     autoClose: 3000
                // }
                // );
            }
        } catch (error) {
        }
    }

    return (
        <div className={styles.container}>
            <div className={styles.section}>
                <p className={styles.titleText}>
                    {!state?.error ? localStorage.getItem('language') === 'ru' ? localeRu.set_password : localeEn.set_password : ''}
                    <br />
                    {!state?.error ? localStorage.getItem('language') === 'ru' ? localeRu.quick_access : localeEn.quick_access : ''}
                </p>
                <div>{state?.error ? localStorage.getItem('language') === 'ru' ? localeRu.pin_mismatch : localeEn.pin_mismatch : ''} <br/> </div>
                <div>{state?.error ? localStorage.getItem('language') === 'ru' ? localeRu.repeat_input : localeEn.repeat_input : ''}</div>
            </div>

            <div className={styles.section}>
                <OtpInput
                    value={otp}
                    onChange={handleOtpChange}
                    numInputs={4}
                    renderSeparator={<span></span>}
                    renderInput={(props, index) => <input
                        {...props}
                        className={styles.otpInput}
                        type="number"
                        pattern="\d*"
                        inputmode="decimal"
                        autoFocus={index === 0}
                    />}
                    containerStyle={styles.otpInlineInput}
                />
            </div>

            <div className={styles.sectionButtons}
                onClick={handleBack}>
                <div className={styles.button}>
                    <img
                        src={arrow}
                        alt="arrow"
                        className={styles.arrow}
                    />
                    <p className={styles.buttonText}>{localStorage.getItem('language') === 'ru' ? localeRu.back : localeEn.back}</p>
                </div>
            </div>
        </div>
    );
}

export default PinCodeOffer;
