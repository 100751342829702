import React, { useEffect, useState } from 'react';
import styles from './RegistrationForm.module.css';
import config from "../../../settings/config"
import { checkToken } from "../../Utils/TokenService";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import leoProfanity from "leo-profanity";
import { toast } from "react-toastify";
import { requestSettings } from './fetchRegForm'; // Импорт функции запроса
import { fetchProfile } from './profileService';
import localeRu from '../../../locales/ru/auth/regForm.json';
import localeEn from '../../../locales/en/auth/regForm.json';
import DOMPurify from "dompurify";
import useAuthToken from "../../../hooks/useAuthToken";
import useStateBackend from "../../../hooks/useStateBackend";



const RegistrationForm = () => {
    leoProfanity.loadDictionary("ru");
    const lang = localStorage.getItem('language') === 'ru';
    console.log(localStorage.getItem('language'));

    const nameSchema = yup.string()
        // .matches(/^[A-ZА-ЯЁ][a-zа-яё]+ [A-ZА-ЯЁ][a-zа-яё]+$/, lang ? localeRu.name_invalid_format : localeEn.name_invalid_format )
        .max(35, lang ? localeRu.name_max_length : localeEn.name_max_length)
        .test("no-profanity", lang ? localeRu.name_profanity : localeEn.name_profanity, (value) => {
            return value ? !leoProfanity.check(value) : true;
        })
        .required(lang ? localeRu.name_required : localeEn.name_required);

    const emailSchema = yup.string()
        .email(lang ? localeRu.email_invalid : localeEn.email_invalid) // Проверка формата email
        .required(lang ? localeRu.email_required : localeEn.email_required);

    const birthdaySchema = yup.date()
        .min(new Date("1900-01-01"), lang ? localeRu.birthday_min : localeEn.birthday_min)
        .max(new Date(), lang ? localeRu.birthday_max : localeEn.birthday_max)
        .required(lang ? localeRu.birthday_required : localeEn.birthday_required);

    const groupedAutos = [
        {
            label: 'Leapmotor',
            code: 'Leapmotor',
            items: [
                { label: 'C01', value: 'Leapmotor С01' },
                { label: 'C11', value: 'Leapmotor С11' },
                { label: 'C16', value: 'Leapmotor С16' },
                { label: 'S01', value: 'Leapmotor S01' }
            ]
        },
        {
            label: 'Nissan',
            code: 'Nissan',
            items: [
                { label: 'Ariya', value: 'Nissan Ariya' },
                { label: 'LEAF', value: 'Nissan LEAF' },
                { label: 'NV200', value: 'Nissan NV200' }
            ]
        },
        {
            label: 'Tesla',
            code: 'Tesla',
            items: [
                { label: 'Cybertruck', value: 'Tesla Cybertruck' },
                { label: 'Model 3', value: 'Tesla Model S' },
                { label: 'Model X', value: 'Tesla Model X' },
                { label: 'Model Y', value: 'Tesla Model Y' }
            ]
        },
        {
            label: 'BMW',
            code: 'BMW',
            items: [
                { label: 'i3', value: 'BMW i3' },
                { label: 'iX', value: 'BMW iX' },
                { label: 'i4', value: 'BMW i4' }
            ]
        }
    ];

    const [name, setName] = useState("");
    const [errorName, setErrorName] = useState("");
    const [email, setEmail] = useState("");
    const [errorEmail, setErrorEmail] = useState("");
    const [birthday, setBirthday] = useState("");
    const [errorBirthday, setErrorBirthday] = useState("");
    const [customCar, setCustomCar] = useState("");
    const [isCustom, setIsCustom] = useState(false);
    const navigate = useNavigate();
    const [isButtonEnabled, setIsButtonEnabled] = useState(false);
    const languagesSlavic = ['ru', 'by', 'ua'];

    useAuthToken();
    useStateBackend();

    useEffect(() => {
        if ((errorName === '' && name !== '') && (errorEmail === '' && email !== '') && errorBirthday === '') {
            setIsButtonEnabled(true);
        } else {
            setIsButtonEnabled(false);
        }
    }, [errorEmail, errorName, email, name, errorBirthday]);



    const handleChangeName = async (e) => {
        const value = e.target.value;
        setName(value);

        try {
            await nameSchema.validate(value);
            setErrorName("");
        } catch (err) {
            setErrorName(err.message);
        }
    };

    const handleChangeEmail = async (e) => {
        const value = e.target.value;
        setEmail(value);

        try {
            await emailSchema.validate(value);
            setErrorEmail("");
        } catch (err) {
            setErrorEmail(err.message);
        }
    };

    const handleChangeBirthday = async (e) => {
        const value = e.target.value;
        setBirthday(value);

        try {
            await birthdaySchema.validate(new Date(value));
            setErrorBirthday("");
        } catch (err) {
            setErrorBirthday(err.message);
        }
    };

    const handleSelectChange = (e) => {
        const selectedValue = e.target.value;
        if (selectedValue === "custom") {
            setIsCustom(true);
            setCustomCar("");
        } else {
            setIsCustom(false);
            setCustomCar(selectedValue);
        }
    };

    const handleChange = (e) => {
    };

    const handleSubmit = (e) => {
        if (!isButtonEnabled) {
            return;
        }
        e.preventDefault();

        let carBrand1 = '';
        let carModel1 = '';
        const customCarSplit = customCar.split(' ');
        if (customCarSplit.length > 1) {
            carBrand1 = customCarSplit[0];
            carModel1 = customCarSplit[1];
        } else {
            carBrand1 = customCar;
        }

        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        let userLang = '';

        if (!localStorage.getItem('language')) {
            const userLanguage = navigator.language || navigator.languages[0];
            let subLanguage = userLanguage?.substring(0, 2)
            if (languagesSlavic.includes(subLanguage)) {
                localStorage.setItem('language', 'ru');
                userLang = 'ru';
            } else {
                localStorage.setItem('language', 'en');
                userLang = 'en';
            }
            localStorage.setItem('language', subLanguage);
        } else {
            userLang = localStorage.getItem('language');
        }

        const requestBody = {
            name: name,
            email: email,
            birthday: birthday,
            carBrand: carBrand1,
            carModel: carModel1,
            lang: userLang,
            timezone: userTimeZone,
        };

        requestSettings(requestBody, navigate, name);
    };

    return (
        <div className={styles.container}>
            <div className={styles.sectionTitle}>
                <h1 className={styles.titleText}>{lang ? localeRu.create_account : localeEn.create_account}</h1>
            </div>
            <div className={styles.mainSection}>
                <p>{lang ? localeRu.welcome_message : localeEn.welcome_message}</p>
                <p className={styles.requiredNote}>{lang ? localeRu.required_fields : localeEn.required_fields}</p>
                <div className={styles.parameter}>
                    <p className={styles.paramText}>{lang ? localeRu.name_label : localeEn.name_label}</p>
                </div>
                <input
                    type="text"
                    className={(errorName === '' && name !== '') ? styles.cardOn : styles.card}
                    value={name}
                    onChange={handleChangeName}
                    placeholder={lang ? localeRu.name_placeholder : localeEn.name_placeholder}
                />
                {errorName && <p style={{ color: "red" }}>{errorName}</p>}
                <div className={styles.parameter}>
                    <p className={styles.paramText}>{lang ? localeRu.email_label : localeEn.email_label}</p>
                </div>
                <input
                    type="text"
                    className={(errorEmail === '' && email !== '') ? styles.cardOn : styles.card}
                    value={email}
                    onChange={handleChangeEmail}
                    placeholder={lang ? localeRu.email_placeholder : localeEn.email_placeholder}
                />
                {errorEmail && <p style={{ color: "red" }}>{errorEmail}</p>}
                <div className={styles.parameter}>
                    <p className={styles.paramText}>{lang ? localeRu.birthdate_label : localeEn.birthdate_label}</p>
                </div>
                <input
                    type="date"
                    className={styles.card}
                    value={birthday}
                    placeholder={lang ? localeRu.date_placeholder : localeEn.date_placeholder}
                    onFocus={(e) => (e.target.type = "date")} // При фокусе превращается в календарь
                    onBlur={(e) => {
                        if (!e.target.value) e.target.type = "text"; // Если пусто, вернуть текст
                    }}
                    onChange={handleChangeBirthday}
                />
                {/* <DatePicker
                    selected={birthday}
                    onChange={(date) => setBirthday(date)}
                    locale={i18n.language} // Меняем язык календаря
                    placeholderText={t("date_placeholder")}
                    dateFormat="dd.MM.yyyy"
                    className={styles.card}
                /> */}
                {errorBirthday && <p style={{ color: "red" }}>{errorBirthday}</p>}
                <div className={styles.formGroup}>
                    <label htmlFor="carModel">{lang ? localeRu.car_label : localeEn.car_label}</label>
                    <select
                        id="carModel"
                        name="carModel"
                        value={isCustom ? "custom" : customCar}
                        onChange={handleSelectChange}
                        className={styles.card}
                    >
                        <option value="">{lang ? localeRu.car_not_selected : localeEn.car_not_selected}</option>
                        {groupedAutos.map((group) => (
                            <optgroup key={group.code} label={group.label}>
                                {group.items.map((car) => (
                                    <option key={car.value} value={car.value}>
                                        {car.value}
                                    </option>
                                ))}
                            </optgroup>
                        ))}
                        <option value="custom">{lang ? localeRu.car_other : localeEn.car_other}</option>
                    </select>

                    {isCustom && (
                        <input
                            type="text"
                            placeholder={lang ? localeRu.car_placeholder : localeEn.car_placeholder}
                            value={customCar}
                            className={styles.card}
                            onChange={(e) => {
                                const value = e.target.value;
                                setCustomCar(value);
                                handleChange({ target: { name: "carModel", value } });
                            }}
                        />
                    )}
                </div>
            </div>
            <div className={styles.submitWrapper}>
                <button
                    type="button"
                    onClick={handleSubmit}
                    className={isButtonEnabled ? styles.submitButtonEnabled : styles.submitButtonDisabled}
                >
                    {lang ? localeRu.next_button : localeEn.next_button}
                    <span className={isButtonEnabled ? styles.arrowEnable : styles.arrow}>➔</span>
                </button>
            </div>
        </div>
    );
};

export default RegistrationForm;
