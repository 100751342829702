import React, { useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import styles from './PinCodeOffer2.module.css'
import OtpInput from 'react-otp-input';
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import HelpIcon from "../../../Utils/HelpIcon/HelpIcon";
import arrow from "../../../../img/arrow-sm-right.svg";
import localeRu from '../../../../locales/ru/auth/pinCodeOffer2.json';
import localeEn from '../../../../locales/en/auth/pinCodeOffer2.json';
import DOMPurify from "dompurify";
import useStateBackend from "../../../../hooks/useStateBackend";

const PinCodeOffer2 = () => {
    const [otp, setOtp] = useState('');
    const { state } = useLocation();
    const navigate = useNavigate();
    const [complite, setComplite] = useState(false);
    const backEndState = useStateBackend();

    const handleSubmit = () => {
        navigate('/checkProfile');
    };

    const handleBack = () => {
        navigate('/pinoffer');
    };

    function handlePinComplite(data) {
        if (data !== state.firstValues) {
            navigate('/pinoffer', { state: { error: true } });
        } else {
            setComplite(true);
            localStorage.setItem('pin', data);
        }
    }

    function handleOtpChange(otp) {
        if (otp.length >= 4) {
            handlePinComplite(otp);
        }
        setOtp(otp);
    }

    return (
        <div className={`${styles.container} ${complite ? styles.completedContainer : ''}`}>
            <div className={styles.section}>
                <div style={complite ? { display: 'none' } : null}>
                    <div className={`${styles.titleText} text-center`}>{localStorage.getItem('language') === 'ru' ? localeRu.repeat_password : localeEn.repeat_password}</div>
                </div>
            </div>
            <div className={styles.section}>
                <div style={complite ? { display: 'none' } : null}>
                    <OtpInput
                        value={otp}
                        onChange={handleOtpChange}
                        numInputs={4}
                        renderSeparator={<span></span>}
                        renderInput={(props, index) => <input
                            {...props}
                            className={styles.otpInput}
                            type="number"
                            pattern="\d*"
                            inputmode="decimal"
                            autoFocus={index === 0}
                        />}
                        containerStyle={styles.otpInlineInput}
                    />
                </div>
                <div style={!complite ? { display: 'none' } : null} className={"text-center"}>
                    <div className={styles.complite}><p className={styles.messageText}>{localStorage.getItem('language') === 'ru' ? localeRu.pin_set : localeEn.pin_set}</p>
                    </div>
                </div>
            </div>
            <div className={complite ? styles.hidden : styles.sectionButtonsBack}>
                <div className={styles.button}
                    onClick={handleBack}>
                    <img
                        src={arrow}
                        alt="arrow"
                        className={styles.arrowIcon}
                    />
                    <p className={styles.buttonText}>{localStorage.getItem('language') === 'ru' ? localeRu.back : localeEn.back}</p>
                </div>
            </div>
            <div className={!complite ? styles.hidden : styles.sectionButtons}>
                <button
                    type="button"
                    onClick={handleSubmit}
                    className={styles.submitButton}
                >
                    {localStorage.getItem('language') === 'ru' ? localeRu.continue : localeEn.continue}
                    <span className={styles.arrow}>➔</span>
                </button>
            </div>
        </div>
    );
}

export default PinCodeOffer2;