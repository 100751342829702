import { useState, useEffect, createContext, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import config from '../settings/config';

const AuthContext = createContext();

const AuthProvider = ({children}) => {
    const [authData, setAuthData] = useState(null);

    return (
        <AuthContext.Provider value={{authData, setAuthData}}>
            {children}
        </AuthContext.Provider>
    );
};

export {AuthContext, AuthProvider};
