import React, { useContext, useEffect, useRef, useState } from 'react';
import styles from './RegistrationStep2.module.css'
import { CountrySelector, defaultCountries, parseCountry } from "react-international-phone";
import 'react-international-phone/style.css';
import InputMask from "react-input-mask";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { AuthContext } from "../../../context/AuthContext.jsx";
import HelpIcon from "../../Utils/HelpIcon/HelpIcon.jsx";
import { getOtpCode } from "./fetchRegStep2.js";
import { fetchPKCEParams } from "../VkAuth/FetchPKCEParams.js";
import { fetchTokens } from "../VkAuth/FetchTokens.js";
import vklogo from "../../../img/vkmini.png"
import TelegramHeader from '../../Utils/TelegramHeader.jsx';
import { useVKAuth } from '../VkAuth/useVkLogic.js'; 
import localeRu from '../../../locales/ru/auth/regStep2.json';
import localeEn from '../../../locales/en/auth/regStep2.json';
import DOMPurify from "dompurify";
import useStateBackend from "../../../hooks/useStateBackend";

/*
VKID.Config.init({
    app: 52225543, // Идентификатор приложения.
    redirectUrl: 'http://localhost', // Адрес для перехода после авторизации.
    state: 'dj29fnsadjsd82', // Произвольная строка состояния приложения.
    codeVerifier: 'FGH767Gd65', // Верификатор в виде случайной строки. Обеспечивает защиту передаваемых данных.
    scope: 'email phone', // Список прав доступа, которые нужны приложению.
    mode: VKID.ConfigAuthMode.Redirect // По умолчанию авторизация открывается в новой вкладке.
});
*/

const countries = defaultCountries.filter((country) => {
    const { iso2 } = parseCountry(country);
    return ['ru', 'by'].includes(iso2);
});

const RegistrationStep2 = (props) => {
    const navigate = useNavigate();
    const { setAuthData } = useContext(AuthContext);
    const [phone, setPhone] = useState(() => {
        return '';
    });
    const [country, setCountry] = useState({
        name: 'Russia',
        iso2: 'ru',
        dialCode: '7',
        format: '(___) ___-__-__',
        priority: undefined
    });
    const [phoneMask, setPhoneMask] = useState('+7 (999) 999-99-99'); // {name: 'Goland', iso2: 'pl', dialCode: '48', format: '...-...-...', priority: undefined, …}
    const [placeholder, setPlaceHolder] = useState('+7 (XXX) XXX XX XX');
    const [isCanChangeKeys, setCanChangeKeys] = useState(false);
    const inputRef = useRef(null);
    const [buttonClass, setButtonClass] = useState(styles.smsButton); // начальный стиль кнопки
    const [isKeyboardVisible, setIsKeyboardVisible] = useState(false);
    const [initialHeight, setInitialHeight] = useState(window.innerHeight);
    let lang = localStorage.getItem('language') === 'ru';
    
    useStateBackend();
    useVKAuth(setAuthData, isCanChangeKeys); 

    useEffect(() => {
        changeStylePhoneInput();
    }, [phone]); 

    const changeStylePhoneInput = () => {
        if (phone.length === phoneMask.length) {
            setButtonClass(styles.smsButtonActive); 
        } else {
            setButtonClass(styles.smsButton);
        }
    }

    useEffect(() => {
        const handleResize = () => {
            const currentHeight = window.innerHeight;
            const heightDifference = initialHeight - currentHeight;
            if (heightDifference > 40) {
                setIsKeyboardVisible(true);
            } else {
                setIsKeyboardVisible(false);
            }
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [initialHeight]);

    function handleSelectCountry(e) {
        setCountry(e);
        let phoneMask = '';
        if (e.format) {
            let reFormat = e.format.replace(/\./g, "9");
            phoneMask = '+' + e.dialCode + ' ' + reFormat;
        } else {
            phoneMask = '+\\' + e.dialCode;
        }
        switch (e.iso2) {
            case "ru":
                setPlaceHolder('+' + e.dialCode + ' (XXX) XXX XX XX');
                break;
            case "by":
                setPlaceHolder('+' + e.dialCode + ' (XX) XXX XX XX');
                break;
        }

        setPhoneMask(phoneMask);
        setPhone('');

        if (inputRef.current) {
            inputRef.current.focused = true;
            inputRef.current.setCursorToEnd();
            inputRef.current.getInputDOMNode().select();
        }
    }

    function handleInputPhone(e) {
        setPhone(e.target.value);
    }

    async function handleGetSMSButton(e) {
        if (phone.length !== phoneMask.length) {
            return;
        }

        if (localStorage.getItem('phone')) {
            if (localStorage.getItem('phone') !== phone.replace(/\D/g, '')) {
                if (localStorage.getItem('pin')) {
                    localStorage.removeItem('pin');
                }
            }
        }

        let resp = '';
        localStorage.setItem('fullPhone', phone);
        localStorage.setItem('phone', phone.replace(/\D/g, ''));
        localStorage.setItem('groupId', phone.replace(/\D/g, ''));
        try {
            resp = await getOtpCode(phone);
        } catch (error) {
            toast.error(error.message, {
                position: "top-center",
                autoClose: 3000
            });
            return;
        }
        let pass = "";
        if (resp.showInToast === "yes") {
            pass = resp.token;
        }
        if (lang) {
            toast.success(`${localeRu.sms_code} ${pass}`, {
                position: "top-center",
                autoClose: 2500,
            });
        } else {
            toast.success(`${localeEn.sms_code} ${pass}`, {
                position: "top-center",
                autoClose: 2500,
            });
        }
        navigate('/step3');
    }

    async function handleLoginOnVK() {
        try {
            fetchPKCEParams();
        } catch (error) {
        } finally {
            setCanChangeKeys(true);
        }
    }

    const handlePolicy = () => {
        window.open("https://zs-pss.app/files/personal-data.pdf", "_blank");
    }

    return (
        <div className={styles.container}>
            <TelegramHeader />
            <div className={styles.section}>
                <div className={styles.titleText}> <p className={styles.titleBigText}>{localStorage.getItem('language') === 'ru' ? localeRu.register_or_login : localeEn.register_or_login} </p></div>
                <div className={styles.textPhoneDiv}>{localStorage.getItem('language') === 'ru' ? localeRu.enter_phone_number : localeEn.enter_phone_number}</div>
                <div className={styles.phoneContainer}>
                    <div className={styles.selectCountryFlagDiv}>
                        <CountrySelector
                            selectedCountry={country.iso2}
                            value={country}
                            countries={countries}
                            onSelect={handleSelectCountry}
                        />
                    </div>
                    <div className={styles.inputPhoneDiv}>
                        <InputMask
                            type="tel"
                            mask={phoneMask}
                            placeholder={placeholder}
                            maskChar={null}
                            value={phone}
                            onChange={handleInputPhone}
                            className={phone.length !== phoneMask.length ? `${styles.input}` : `${styles.inputSuccess}`}
                            style={{ fontWeight: '600' }}
                        />
                    </div>
                </div>
                <button
                    className={buttonClass}
                    onClick={handleGetSMSButton}
                >
                    {localStorage.getItem('language') === 'ru' ? localeRu.get_sms_code : localeEn.get_sms_code}
                </button>
                <div className={styles.or}>
                </div>
                <button
                    className={styles.vkButton}
                    onClick={handleLoginOnVK}
                >
                    <img
                        src={vklogo}
                        alt="VK logo"
                        className={styles.vkLogo}
                    /> {localStorage.getItem('language') === 'ru' ? localeRu.continue_with_vk : localeEn.continue_with_vk}
                </button>
            </div>
            <div className={styles.sectionPrivacy}>
                {!isKeyboardVisible && (
                    <div 
                    className={styles.privacyText}
                    onClick={handlePolicy}
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(
                            localStorage.getItem("language") === "ru"
                                ? localeRu.privacy_policy
                                : localeEn.privacy_policy
                        ),
                    }}                    
                    >
                    </div>
                )}
            </div>
        </div>
    );
};

export default RegistrationStep2;
