import React, { useEffect, useContext, useState } from 'react';
import styles from './NStartScreen.module.css';
import { useNavigate } from "react-router-dom";
import { postEvent } from '@telegram-apps/sdk';
import TelegramHeader from '../../Utils/TelegramHeader';
import { useVKAuth } from '../VkAuth/useVkLogic.js'; // Импортируем хук
import { AuthContext } from "../../../context/AuthContext.jsx";
import localeRu from '../../../locales/ru/auth/nstartscreen.json';
import localeEn from '../../../locales/en/auth/nstartscreen.json';
import useStateBackend from "../../../hooks/useStateBackend";

const NStartScreen = () => {
    const navigate = useNavigate();
    // postEvent('web_app_add_to_home_screen', { color_key: 'bg_color' });
    const { setAuthData } = useContext(AuthContext);
    const [isCanChangeKeys, setCanChangeKeys] = useState(false);
    const languagesSlavic = ['ru', 'by', 'ua'];
    localStorage.setItem('pinAttempts', 3);
    
    useStateBackend();
    useVKAuth(setAuthData, isCanChangeKeys); // Используем хук

    useEffect(() => {
        if (!localStorage.getItem('language')) {
            const userLanguage = navigator.language || navigator.languages[0];
            let subLanguage = userLanguage?.substring(0, 2)
            if (languagesSlavic.includes(subLanguage)) {
                localStorage.setItem('language', 'ru');  
            } else {
                localStorage.setItem('language', 'en'); 
            }
            localStorage.setItem('language', subLanguage);  
        }

        if (localStorage.getItem('vkloginprocess') && !localStorage.getItem("pin")) {
            navigate('/pinoffer');
        } else if (localStorage.getItem('vkloginprocess') && localStorage.getItem("pin")) {
            navigate('/pin');  
        } else if (!localStorage.getItem('m_token') && localStorage.getItem("pin")) {
            // toast.error('Сессия истекла, авторизируйтесь снова:', {
            //     position: "top-center",
            //     autoClose: 3000
            // });
            console.log("TEST SC")
            return;
        } else if (localStorage.getItem("pin")) {
            navigate('/pin');
        } else {
            // clear local storage (удаляем из localStorage всю информацию за исключением телефона)
            for (let i = 0, len = localStorage.length; i < len; ++i) {
                if (localStorage.key(i) === 'phone') {
                    continue;
                }
                if (localStorage.key(i) === 'vkloginprocess') {
                    continue;
                }
                if (localStorage.key(i) === 'pin') {
                    continue;
                }
                if (localStorage.key(i) === 'language') {
                    continue;
                }
                localStorage.removeItem(localStorage.key(i));
            }
            navigate('/start');
        }
    }, [navigate]);

    const handleBegin = () => {
        navigate('/step2');
    }

    return (
        <div className={styles.container}>
            <TelegramHeader />
            <div className={styles.imageWrapper}></div>
            <div className={styles.content}>
                <h1 className={styles.title}>PSS</h1>
                <p className={styles.description}>
                    {/* Откройте для себя простой и быстрый доступ к зарядным станциям по всему миру */}
                    {localStorage.getItem('language') === 'ru' ? localeRu.description : localeEn.description}
                </p>
                <button className={styles.startButton} onClick={handleBegin}>
                    <p className={styles.textButton}>{localStorage.getItem('language') === 'ru' ? localeRu.start_button : localeEn.start_button}</p>
                    <span className={styles.arrow}>➔</span>
                </button>
            </div>
        </div>
    );
};

export default NStartScreen;
