import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import styles from './StationShare.module.css';
import { useNavigate } from "react-router-dom";
import config from '../../../settings/config';
import { toast } from "react-toastify";
import Header from '../../Utils/Header/Header';
import localeRu from '../../../locales/ru/stations/stationShare.json';
import localeEn from '../../../locales/en/stations/stationShare.json';
import useAuthToken from "../../../hooks/useAuthToken";
import useStateBackend from "../../../hooks/useStateBackend";
import InputMask from "react-input-mask";
import { CountrySelector, defaultCountries, parseCountry } from "react-international-phone";
import mobileSignal from '../../../img/signal.svg';

const countries = defaultCountries.filter((country) => {
    const { iso2 } = parseCountry(country);
    return ['ru', 'by'].includes(iso2);
});

const StationShare = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const [station, setStation] = useState(location.state?.station);

    let lang = localStorage.getItem('language') === 'ru';
    const [phone, setPhone] = useState(() => {
        return '';
    });
    const [country, setCountry] = useState({
        name: 'Russia',
        iso2: 'ru',
        dialCode: '7',
        format: '(___) ___-__-__',
        priority: undefined
    });
    const [phoneMask, setPhoneMask] = useState('+7 (999) 999-99-99'); // {name: 'Goland', iso2: 'pl', dialCode: '48', format: '...-...-...', priority: undefined, …}
    const [placeholder, setPlaceHolder] = useState('+7 (XXX) XXX XX XX');
    const inputRef = useRef(null);

    const token = useAuthToken();
    useStateBackend();

    function handleSelectCountry(e) {
        setCountry(e);
        let phoneMask = '';
        if (e.format) {
            let reFormat = e.format.replace(/\./g, "9");
            phoneMask = '+' + e.dialCode + ' ' + reFormat;
        } else {
            phoneMask = '+\\' + e.dialCode;
        }
        switch (e.iso2) {
            case "ru":
                setPlaceHolder('+' + e.dialCode + ' (XXX) XXX XX XX');
                break;
            case "by":
                setPlaceHolder('+' + e.dialCode + ' (XX) XXX XX XX');
                break;
        }

        setPhoneMask(phoneMask);
        setPhone('');

        if (inputRef.current) {
            inputRef.current.focused = true;
            inputRef.current.setCursorToEnd();
            inputRef.current.getInputDOMNode().select();
        }
    }

    const requestAddStationAnotherUser = async () => {
        if (phone.length < 11) {
            toast.warn(`${lang ? localeRu.phoneNumberTooShort : localeEn.phoneNumberTooShort}`, {
                position: "top-center",
                autoClose: 3000,
            });
            return;
        }

        let resp = '';

        if (token) {
            try {
                const response = await fetch(`${config.app.host}/api/v1/station/toshare/?sn=${station.sn}&phone=${phone}`, {
                    method: 'GET',
                    mode: 'cors',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });

                resp = await response.json();

                if (resp.status === 400) {
                    toast.warn(`${lang ? localeRu[resp.code] : localeEn[resp.code]} ${phone}`, {
                        position: "top-center",
                        autoClose: 3000
                    });
                    setPhone('');
                    return;
                }
                toast.success(`${lang ? localeRu.connectSuccess : localeEn.connectSuccess} ${phone}`, {
                    position: "top-center",
                    autoClose: 3000
                });

                navigate('/stationControl', { state: { station } });

            } catch (error) {
                toast.error(lang ? localeRu.error : localeEn.error, {
                    position: "top-center",
                    autoClose: 3000
                });
                setPhone('');
            }
        }


    };

    function handleInputPhone(e) {
        setPhone(e.target.value.replace(/\D/g, ''));
    }

    return (
        <div className={styles.container}>
            <Header />
            <div className={styles.sectionTitle}>
                <p className={styles.titleText}>{lang ? localeRu.titleName : localeEn.titleName}</p>
                <p className={styles.titleText}>{station.nickName}</p>
            </div>
            <div className={styles.mainSection}>
                <p>{lang ? localeRu.notification : localeEn.notification}</p>
                <div className={styles.phoneContainer}>
                    <div className={styles.selectCountryFlagDiv}>
                        <CountrySelector
                            selectedCountry={country.iso2}
                            value={country}
                            countries={countries}
                            onSelect={handleSelectCountry}
                        />
                    </div>
                    <div className={styles.inputPhoneDiv}>
                        <InputMask
                            type="tel"
                            mask={phoneMask}
                            placeholder={placeholder}
                            maskChar={null}
                            value={phone}
                            onChange={handleInputPhone}
                            className={phone.length !== phoneMask.length ? `${styles.input}` : `${styles.inputSuccess}`}
                            style={{ fontWeight: '600' }}
                        />
                    </div>
                </div>
                <button
                    type="button"
                    onClick={requestAddStationAnotherUser}
                    className={
                        phone.length >= 11
                            ? styles.submitButtonConnect
                            : styles.submitButtonConnectDis 
                    }
                >
                    <p className={styles.textButtons}>{localStorage.getItem('language') === 'ru' ? localeRu.connect : localeEn.connect}</p>
                    <img src={mobileSignal} className={styles.mobileSignal} alt='signal' />
                </button>
            </div>
        </div>
    );

}


export default StationShare;